import produce from "immer";
import { SET_PLAN, SET_PLANS } from "redux/constants/Plan";

const initState = {
  planList: [],
  plan: null,
};

const planReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_PLANS:
        draft.planList = {
          count: payload?.total,
          current: payload?.page,
          size: payload?.size,
          results: payload?.items,
        };
        break;
      case SET_PLAN:
        draft.plan = payload;
        break;
      default:
        return state;
    }
  });
export default planReducer;
