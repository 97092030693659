import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LanguageProvider from "components/util-components/LanguageProvider";
import { translationMessages } from "./i18n";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import history from "./utils/history";
import { GoogleOAuthProvider } from "@react-oauth/google";

const MOUNT_NODE = document.getElementById("root");

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <LanguageProvider messages={messages}>
            <App />
          </LanguageProvider>
        </GoogleOAuthProvider>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

render(translationMessages);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
