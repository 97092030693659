import { GET_INVOICES, SET_INVOICES } from "redux/constants/Invoice";

export const getInvoices = (payload) => ({
  type: GET_INVOICES,
  payload,
});

export const setInvoices = (payload) => ({
  type: SET_INVOICES,
  payload,
});
