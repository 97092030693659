import PROMPTS from "components/shared-components/HelpPrompt/constants";
import { CHOOSE_ORIGINAL_TEXT_DROPDOWN } from "components/shared-components/HelpPrompt/constants/ComparePagePrompts";
import { SPEAKER_VOICE_DROPDOWN } from "components/shared-components/HelpPrompt/constants/ConvertPagePrompts";
import { ADD_SPEAKERS_PROMPT } from "components/shared-components/HelpPrompt/constants/EditTranscriptPrompts";
import { SETTINGS_BUTTON } from "components/shared-components/HelpPrompt/constants/HomepagePrompts";
import { CHOICE_PROMPT } from "components/shared-components/HelpPrompt/constants/UploadPagePrompts";
import produce from "immer";
import {
  PROMPT_TRANSITION,
  RESET_ALL_PROMPTS,
  SET_PROMPT_LIST,
} from "redux/constants/Prompt";
import { ROUTES } from "routes";
import { formatPathName } from "helpers/formatPathName";

const initState = {
  machineState: {
    [ROUTES.HOME]: SETTINGS_BUTTON,
    [ROUTES.UPLOAD]: CHOICE_PROMPT,
    [ROUTES.EDIT_TRANSCRIPT]: ADD_SPEAKERS_PROMPT,
    [ROUTES.PROJECT]: "empty",
    [ROUTES.COMPARE]: CHOOSE_ORIGINAL_TEXT_DROPDOWN,
    [ROUTES.CONVERT]: SPEAKER_VOICE_DROPDOWN,
  },
  states: PROMPTS,
};

const promptReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case PROMPT_TRANSITION:
        let pathName = formatPathName(window.location.pathname);
        const statesList = [...draft.states[pathName]];
        if (statesList.includes(draft.machineState[pathName])) {
          const index = statesList.indexOf(draft.machineState[pathName]);
          if (
            [...draft.states[pathName]].pop() === draft.machineState[pathName]
          ) {
            draft.machineState[pathName] = "empty";
          } else if (!payload) {
            draft.machineState[pathName] = statesList[index + 1];
          } else {
            draft.machineState[pathName] = statesList[payload];
          }
        }
        if (payload === 0) {
          draft.machineState[pathName] = statesList[payload];
        }
        break;
      case SET_PROMPT_LIST:
        let path = formatPathName(window.location.pathname);
        draft.states[path] = [...payload];
        break;
      case RESET_ALL_PROMPTS:
        draft.machineState = {
          [ROUTES.HOME]: SETTINGS_BUTTON,
          [ROUTES.UPLOAD]: CHOICE_PROMPT,
          [ROUTES.EDIT_TRANSCRIPT]: ADD_SPEAKERS_PROMPT,
          [ROUTES.PROJECT]: "empty",
          [ROUTES.COMPARE]: CHOOSE_ORIGINAL_TEXT_DROPDOWN,
          [ROUTES.CONVERT]: SPEAKER_VOICE_DROPDOWN,
        };
        break;
      default:
        return state;
    }
  });
export default promptReducer;
