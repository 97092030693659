import ApiService from "./ApiService";

const ENDPOINTS = {
  UPLOAD_SPEECH_FILE: "speech-to-text/v2/upload",
  UPLOAD_TEXT_FILE: "",
  GET_LANGUAGES: "transcript/all/languages",
};

class UploadFileService extends ApiService {
  uploadSpeechFile = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.UPLOAD_SPEECH_FILE,
      payload.data,
      payload.options
    );
    return data;
  };
  getLanguages = () => {
    return this.apiClient.get(ENDPOINTS.GET_LANGUAGES);
  };
}

export default new UploadFileService();
