import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  ProfileOutlined,
  SettingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home.navbar.myprojects",
    breadcrumb: false,
    submenu: [],
    icon: ProfileOutlined,
    dataCy: "headerHome",
  },
  {
    key: "upload",
    path: `${APP_PREFIX_PATH}/upload`,
    title: "home.navbar.newproject",
    breadcrumb: false,
    submenu: [],
    icon: PlusCircleOutlined,
    dataCy: "headerUpload",
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "home.navbar.settings",
    breadcrumb: false,
    submenu: [],
    icon: SettingOutlined,
    dataCy: "headerSettings",
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;

export const routeData = [
  { path: `${APP_PREFIX_PATH}/home`, title: "My Projects" },
  { path: `${APP_PREFIX_PATH}/upload`, title: "Upload Files" },
  { path: `${APP_PREFIX_PATH}/settings`, title: "Settings" },
  {
    path: `${APP_PREFIX_PATH}/translator/:id`,
    title: "Text Editor/Translator",
  },
  { path: `${APP_PREFIX_PATH}/compare/:id`, title: "Compare Translation" },
  { path: `${APP_PREFIX_PATH}/convert/:id`, title: "Choose Speakers Page" },
  { path: `${APP_PREFIX_PATH}/projects/:id`, title: "Project Detail Page" },
  {
    path: `${APP_PREFIX_PATH}/transcript/:id`,
    title: "Edit Transcription Page",
  },
  {
    path: `${APP_PREFIX_PATH}/checkout/:id`,
    title: "Checkout Page",
  },
  { path: `${APP_PREFIX_PATH}/prices/`, title: "Pricing Page" },
];
