import ApiService from "./ApiService";

const ENDPOINTS = {
  GET_LOCATION: "/geolocation/location",
};

class LocationService extends ApiService {
  getLocation = async () => {
    return await this.apiClient.get(ENDPOINTS.GET_LOCATION);
  };
}

export default new LocationService();
