import { message } from "antd";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { NUMBER_PER_PAGE } from "../constants/Projects";
import { DELETE_AUDIO, RENAME_AUDIO } from "../constants/Audio";
import AudioService from "services/AudioService";
import { getTranslationPerPage } from "../actions/Translations";
import { getProject } from "../actions/Projects";

export function* deleteAudioSaga() {
  yield takeEvery(DELETE_AUDIO, function* ({ payload }) {
    try {
      const response = yield call(AudioService.deleteAudio, payload.id);
      message.success(response.data.message, 3);
      if (payload.hasOwnProperty("filter")) {
        yield put(
          getTranslationPerPage({
            orderBy: payload.orderBy,
            page: payload.page,
            perPage: NUMBER_PER_PAGE,
            filter: payload.filter,
            projectId: payload.projectId,
          })
        );
      } else {
        yield put(getProject(payload.projectId, null));
      }
      payload.setLoaderActive(false);
    } catch (error) {
      message.error("Unknown Error Ocurred");
      payload.setLoaderActive(false);
      console.log("error: ", error.response.data.message);
    }
  });
}

export function* renameAudioSaga() {
  yield takeEvery(RENAME_AUDIO, function* ({ payload }) {
    try {
      const response = yield call(AudioService.renameAudio, {
        projectId: payload.id,
        name: payload.name,
      });
      message.success(response.data.message, 3);
      yield put(
        getTranslationPerPage({
          orderBy: payload.orderBy,
          page: payload.page,
          perPage: NUMBER_PER_PAGE,
          filter: payload.filter,
          projectId: payload.projectId,
        })
      );
      yield put(getProject(payload?.projectId, null));
      payload.setLoaderActive(false);
    } catch (error) {
      payload.setLoaderActive(false);
      if (error?.response?.data?.detail[0]?.msg) {
        message.error(error?.response?.data?.detail[0]?.msg, 3);
      }
      console.log(error);
      console.log("error: ", error?.response?.data.detail);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(deleteAudioSaga), fork(renameAudioSaga)]);
}
