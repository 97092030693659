import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  GET_TRANSCRIPT_PER_PAGE,
  GET_LANGUAGES,
  DOWNLOAD_FILE,
  GET_TRANSCRIPT,
  CREATE_TRANSCRIPT,
  ADD_NEW_SPEAKER,
  DELETE_TRANSCRIPT,
  UPDATE_TRANSCRIPT,
  RENAME_TRANSCRIPT,
  DOWNLOAD_TRANSCRIPT_FILE,
} from "../constants/Transcript";
import {
  setTranscriptPerPage,
  setLanguages,
  setTranscript,
} from "../actions/Transcript";
import { setSpeakerSamples, setVoices } from "../actions/TextToSpeech";
import TranscriptService from "services/TranscriptService";
import FileDownload from "js-file-download";
import { getProject } from "../actions/Projects";

export function* getTranscriptPerPage() {
  yield takeEvery(GET_TRANSCRIPT_PER_PAGE, function* ({ payload }) {
    try {
      const response = yield call(
        TranscriptService.getTranscriptPerPage,
        payload
      );
      yield put(setTranscriptPerPage(response.data));
      yield put(setSpeakerSamples([]));
      yield put(setVoices([]));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* getLanguages() {
  yield takeEvery(GET_LANGUAGES, function* () {
    try {
      const response = yield call(TranscriptService.getLanguages);
      yield put(setLanguages(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* downloadFileSpeech() {
  yield takeEvery(DOWNLOAD_FILE, function* ({ payload, setLoaderActive }) {
    try {
      const response = yield call(TranscriptService.downloadFile, payload);
      FileDownload(response.data, payload.name);
      setLoaderActive(false);
    } catch (error) {
      console.log("error", error.response);
      setLoaderActive(false);
    }
  });
}

export function* getTranscript() {
  yield takeEvery(GET_TRANSCRIPT, function* ({ payload, redirect }) {
    try {
      yield put(setTranscript(null));
      const response = yield call(TranscriptService.getTranscript, payload);
      yield put(setTranscript(response.data));
    } catch (error) {
      console.log("error: ", error.response.data.message);
      message.error(error.response.data.message, 3).then(() => {
        redirect();
      });
    }
  });
}

export function* createTranscript() {
  yield takeEvery(CREATE_TRANSCRIPT, function* ({ payload, setLoaderActive }) {
    try {
      const response = yield call(TranscriptService.createTranscript, payload);
      yield put(setTranscript(response.data));
      setLoaderActive(false);
    } catch (error) {
      console.log("error: ", error.response.data.message);
      setLoaderActive(false);
    }
  });
}

export function* addSpeaker() {
  yield takeEvery(
    ADD_NEW_SPEAKER,
    function* ({ payload, setLoaderActive, handleNewSpeakerSuccess }) {
      try {
        yield call(TranscriptService.addSpeaker, payload);
        handleNewSpeakerSuccess();
        setLoaderActive(false);
      } catch (error) {
        console.log("error: ", error.response.data.message);
        setLoaderActive(false);
      }
    }
  );
}

export function* deleteTranscript() {
  yield takeEvery(DELETE_TRANSCRIPT, function* ({ payload }) {
    try {
      const response = yield call(
        TranscriptService.deleteTranscript,
        payload.id
      );
      message.success(response.data.message, 3);
      yield put(getProject(payload.projectId));
      payload.setLoaderActive(false);
    } catch (error) {
      message.error("Unknown Error Occurred");
      payload.setLoaderActive(false);
      console.log("error: ", error.response.data.message);
    }
  });
}

export function* updateTranscript() {
  yield takeEvery(
    UPDATE_TRANSCRIPT,
    function* ({ payload, setLoaderActive, redirectToProject }) {
      try {
        yield call(TranscriptService.updateTranscript, payload);
        redirectToProject.redirect(redirectToProject.project);
      } catch (error) {
        message.error(error.response?.data?.message, 3);
        setLoaderActive(false);
      }
    }
  );
}

export function* renameTranscript() {
  yield takeEvery(RENAME_TRANSCRIPT, function* ({ payload }) {
    try {
      const response = yield call(TranscriptService.renameTranscript, {
        transcript_id: payload.id,
        name: payload.name,
      });
      message.success(response.data.message, 3);
      yield put(getProject(payload?.projectId, null));
      payload.setLoaderActive(false);
    } catch (error) {
      payload.setLoaderActive(false);
      if (error?.response?.data?.detail[0]?.msg) {
        message.error(error?.response?.data?.detail[0]?.msg, 3);
      }
      console.log(error);
      console.log("error: ", error?.response?.data.detail);
    }
  });
}

export function* downloadTranscriptFile() {
  yield takeEvery(
    DOWNLOAD_TRANSCRIPT_FILE,
    function* ({ payload, setLoaderActive }) {
      try {
        const response = yield call(
          TranscriptService.downloadTranscriptFile,
          payload
        );
        FileDownload(response.data, payload.name + payload.format);
        setLoaderActive(false);
      } catch (error) {
        console.log("error", error.response);
        setLoaderActive(false);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getTranscriptPerPage),
    fork(getLanguages),
    fork(getTranscript),
    fork(downloadFileSpeech),
    fork(createTranscript),
    fork(addSpeaker),
    fork(deleteTranscript),
    fork(updateTranscript),
    fork(renameTranscript),
    fork(downloadTranscriptFile),
  ]);
}
