export const NUMBER_PER_PAGE = 10;

export const ORDER_BY = {
  ORIGINAL_LANGUAGE: "original_language",
  TRANSLATED_LANGUAGE: "translated_language",
  UPDATED_AT: "updated_at",
  NAME: "original_file_name",
};

export const LANGUAGES_ALL = "All";

export const TRANSLATION_STATUS = {
  IN_PROGRESS: "in progress",
  VOICING: "voicing",
  VOICED: "voiced",
  FAILED: "failed",
};

export const DEFAULT_LANGUAGE = "English";

export const TYPE_FILE = [
  { name: "MP3", code: ".mp3" },
  { name: "WAV", code: ".wav" },
  { name: "M4A", code: ".m4a" },
];

// Might not need this here, TODO: check all these :)
export const UPLOAD_AUDIO_TYPE = [
  "audio/x-m4a",
  "audio/flac",
  "audio/wav",
  "audio/mpeg",
];

export const SPEED_RATE_TYPES = ["x-slow", "slow", "medium", "fast", "x-fast"];
export const SPEED_RATE_MIN_VALUE = 20;
export const SPEED_RATE_MAX_VALUE = 200;
export const SPEED_RATE_DEFAULT_VALUE_PERCENTAGE = 100;
export const SPEED_RATE_DEFAULT_VALUE = "medium";

export const PITCH_TYPES = [
  "default",
  "x-low",
  "low",
  "medium",
  "high",
  "x-high",
];
export const PITCH_MIN_VALUE = -100;
export const PITCH_MAX_VALUE = 100;
export const PITCH_DEFAULT_VALUE_PERCENTAGE = 0;
export const PITCH_DEFAULT_VALUE = "default";

export const TYPE_VALUES_FEATURES = {
  PERCENTAGE: 1,
  VALUE: 2,
};

export const TRANSLATION_STATUS_TEXT = {
  VOICING: "Voicing",
  VOICED: "Voiced",
  FAILED: "Failed",
};

export const RENDER_TRANSLATION_STATUS = {
  [TRANSLATION_STATUS.IN_PROGRESS]: TRANSLATION_STATUS_TEXT.VOICING,
  [TRANSLATION_STATUS.VOICING]: TRANSLATION_STATUS_TEXT.VOICING,
  [TRANSLATION_STATUS.VOICED]: TRANSLATION_STATUS_TEXT.VOICED,
  [TRANSLATION_STATUS.FAILED]: TRANSLATION_STATUS_TEXT.FAILED,
};

export const audioFormatSwitch = (audio) => {
  switch (audio.type) {
    case "audio/x-m4a":
      return "M4A";
    case "audio/mpeg":
      return "MP3";
    case "audio/wav":
      return "WAV";
    case "audio/flac":
      return "FLAC";
    default:
      break;
  }
  return audio.type;
};

export const SPECIAL_LANGUAGES = {
  CHINESE: "Chinese",
  JAPANESE: "Japanese",
  RUSSIAN: "Russian",
};

export const LANGUAGE_STRINGS = {
  US_ENGLISH: "US English",
  CHINESE_MANDARIN: "Chinese Mandarin",
  JAPANESE: "Japanese",
  KOREAN: "Korean",
  RUSSIAN: "Russian",
};

export const COMPATIBLE_VOICES = {
  [SPECIAL_LANGUAGES.CHINESE]: [
    LANGUAGE_STRINGS.CHINESE_MANDARIN,
    LANGUAGE_STRINGS.JAPANESE,
    LANGUAGE_STRINGS.KOREAN,
  ],
  [SPECIAL_LANGUAGES.JAPANESE]: [
    LANGUAGE_STRINGS.CHINESE_MANDARIN,
    LANGUAGE_STRINGS.JAPANESE,
    LANGUAGE_STRINGS.KOREAN,
    LANGUAGE_STRINGS.US_ENGLISH,
  ],
  [SPECIAL_LANGUAGES.RUSSIAN]: [LANGUAGE_STRINGS.RUSSIAN],
};
