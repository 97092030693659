export const SIGNIN = "SIGNIN";
export const GOOGLE_SIGNIN = "GOOGLE_SIGNIN";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "access_token";
export const CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_ERROR = "CONFIRM_RESET_PASSWORD_ERROR";

export const SEND_FORGOT_PASSWORD_EMAIL = "SEND_FORGOT_PASSWORD_EMAIL";
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_EMAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_EMAIL_ERROR =
  "SEND_FORGOT_PASSWORD_EMAIL_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const SET_IS_VALIDATE = "SET_IS_VALIDATE";
export const UPDATE_FILE_SETTINGS = "UPDATE_FILE_SETTINGS";
