import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { GET_ALL_LANGUAGES, UPLOAD_SPEECH_FILE } from "../constants/UploadFile";
import UploadFileService from "services/UploadFileService";
import { ROUTES } from "routes";
import { push, go } from "connected-react-router";
import { setAllLanguages } from "redux/actions/UploadFile";
import { message } from "antd";

export function* uploadSpeachFile() {
  yield takeEvery(UPLOAD_SPEECH_FILE, function* ({ payload, redirect }) {
    try {
      yield call(UploadFileService.uploadSpeechFile, payload);
      yield put(push(ROUTES.HOME));
      yield put(go());
    } catch (error) {
      message.destroy("wait-message");
      function onClose() {
        redirect.push(ROUTES.HOME);
      }
      message.error({ content: error?.response?.data?.detail, onClose }, 8);

      console.log("error", error.response);
    }
  });
}

export function* getAllLanguages() {
  yield takeEvery(GET_ALL_LANGUAGES, function* () {
    try {
      const response = yield call(UploadFileService.getLanguages);
      yield put(setAllLanguages(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(uploadSpeachFile), fork(getAllLanguages)]);
}
