import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_USER_INFO,
  SET_IS_VALIDATE,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  currentUser: null,
  success: false,
  isValidateToken: false,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.response.data.password,
        showMessage: true,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...state,
      };
    }
    case SET_USER_INFO:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_IS_VALIDATE:
      return {
        ...state,
        isValidateToken: true,
      };
    default:
      return state;
  }
};

export default auth;
