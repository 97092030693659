import ApiService from "./ApiService";

const ENDPOINTS = {
  GET_AUDIO: "/audio/",
};

class AudioService extends ApiService {
  deleteAudio = (projectId) => {
    return this.apiClient.delete(`${ENDPOINTS.GET_AUDIO}${projectId}`);
  };

  renameAudio = ({ projectId, name }) => {
    return this.apiClient.put(`${ENDPOINTS.GET_AUDIO}${projectId}`, {
      name: name,
    });
  };
}

export default new AudioService();
