import { createSelector } from "reselect";
import reducers from "../reducers";

const selectPrompt = (state) => state?.prompt || reducers;

const selectPathName = () => {
  const url = window.location.pathname;
  const lastSlash = url.lastIndexOf("/");
  if (!url.substring(lastSlash + 1).match(/[A-Z|a-z|ü|é]/i)) {
    return url.slice(0, lastSlash + 1);
  }
  return url;
};

const selectCurrentPromptState = createSelector(
  selectPrompt,
  selectPathName,
  (substate, pathName) => {
    return substate.machineState[pathName];
  }
);

const makeSelectIsFinal = () => {
  return createSelector(
    selectPrompt,
    selectPathName,
    selectCurrentPromptState,
    (substate, pathName, machineState) =>
      !!substate.states[pathName][machineState].final
  );
};

const isActivePrompt = (name) => {
  return createSelector(selectCurrentPromptState, (machineState) => {
    return machineState === name;
  });
};

const selectActivePrompt = (name) => {
  return createSelector(selectCurrentPromptState, (machineState) => {
    return machineState;
  });
};

export { makeSelectIsFinal, isActivePrompt, selectActivePrompt };
