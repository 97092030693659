import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_TRANSCRIPTS:
    "transcript/?order_by=%s&filter=%s&page=%s&size=%s&project_id=%s",
  GET_LANGUAGES: "translate/languages",
  DOWNLOAD_FILE: "/file/%s/%s",
  GET_TRANSCRIPT: "transcript/",
  ADD_NEW_SPEAKER: "transcript/speaker",
  UPDATE_TRANSCRIPT: "transcript/",
  DOWNLOAD_TRANSCRIPT_FILE: "/transcript/export_file/%s/%s",
};

class TranscriptService extends ApiService {
  getTranscriptPerPage = async ({
    orderBy,
    filter,
    page,
    perPage,
    projectId = "",
  }) => {
    return await this.apiClient.get(
      format(
        ENDPOINTS.GET_TRANSCRIPTS,
        orderBy,
        filter,
        page,
        perPage,
        projectId
      )
    );
  };

  getLanguages = () => {
    return this.apiClient.get(ENDPOINTS.GET_LANGUAGES);
  };

  downloadFile = (data) => {
    return this.apiClient.get(
      format(ENDPOINTS.DOWNLOAD_FILE, data.id, data.name),
      { responseType: "blob" }
    );
  };

  getTranscript = async (payload) => {
    return await this.apiClient.get(`${ENDPOINTS.GET_TRANSCRIPT}${payload}`);
  };

  createTranscript = (payload) => {
    return this.apiClient.post(ENDPOINTS.GET_TRANSCRIPT, payload);
  };
  addSpeaker = (payload) => {
    return this.apiClient.post(ENDPOINTS.ADD_NEW_SPEAKER, payload);
  };
  deleteTranscript = (payload) => {
    return this.apiClient.delete(ENDPOINTS.GET_TRANSCRIPT + payload);
  };
  updateTranscript = (payload) => {
    return this.apiClient.put(
      ENDPOINTS.UPDATE_TRANSCRIPT + payload.transcript_id,
      payload
    );
  };
  renameTranscript = (payload) => {
    return this.apiClient.patch(
      ENDPOINTS.UPDATE_TRANSCRIPT + payload.transcript_id,
      payload
    );
  };

  downloadTranscriptFile = (data) => {
    return this.apiClient.get(
      format(
        ENDPOINTS.DOWNLOAD_TRANSCRIPT_FILE,
        data.id,
        data.format.substring(1)
      ),
      { responseType: "blob" }
    );
  };
}
export default new TranscriptService();
