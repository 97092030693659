import {
  GET_TRANSCRIPT_PER_PAGE,
  SET_TRANSCRIPT_PER_PAGE,
  GET_LANGUAGES,
  SET_LANGUAGES,
  DOWNLOAD_FILE,
  GET_TRANSCRIPT,
  SET_TRANSCRIPT,
  CREATE_TRANSCRIPT,
  ADD_NEW_SPEAKER,
  DELETE_TRANSCRIPT,
  UPDATE_TRANSCRIPT,
  RENAME_TRANSCRIPT,
  DOWNLOAD_TRANSCRIPT_FILE,
} from "../constants/Transcript";

export const getTranscriptPerPage = (payload) => {
  return {
    type: GET_TRANSCRIPT_PER_PAGE,
    payload,
  };
};

export const setTranscriptPerPage = (payload) => {
  return {
    type: SET_TRANSCRIPT_PER_PAGE,
    payload,
  };
};

export function getLanguages() {
  return {
    type: GET_LANGUAGES,
  };
}

export const setLanguages = (payload) => {
  return {
    type: SET_LANGUAGES,
    payload,
  };
};

export const downloadFile = (payload, setLoaderActive) => {
  return {
    type: DOWNLOAD_FILE,
    payload,
    setLoaderActive,
  };
};
export const getTranscript = (payload, redirect) => {
  return {
    type: GET_TRANSCRIPT,
    payload,
    redirect,
  };
};

export const setTranscript = (payload) => {
  return {
    type: SET_TRANSCRIPT,
    payload,
  };
};

export const createTranscript = (payload, setLoaderActive) => {
  return {
    type: CREATE_TRANSCRIPT,
    payload,
    setLoaderActive,
  };
};

export const addSpeaker = (
  payload,
  setLoaderActive,
  handleNewSpeakerSuccess
) => {
  return {
    type: ADD_NEW_SPEAKER,
    payload,
    setLoaderActive,
    handleNewSpeakerSuccess,
  };
};

export const deleteTranscript = (payload) => {
  return {
    type: DELETE_TRANSCRIPT,
    payload,
  };
};

export const updateTranscription = (
  payload,
  setLoaderActive,
  redirectToProject
) => {
  return {
    type: UPDATE_TRANSCRIPT,
    payload,
    setLoaderActive,
    redirectToProject,
  };
};

export const renameTranscript = (payload) => ({
  type: RENAME_TRANSCRIPT,
  payload,
});

export const downloadTranscriptFile = (payload, setLoaderActive) => {
  return {
    type: DOWNLOAD_TRANSCRIPT_FILE,
    payload,
    setLoaderActive,
  };
};
