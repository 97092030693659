import { ROUTES } from "routes";
import {
  CHOOSE_ORIGINAL_TEXT_DROPDOWN,
  REVERSE_TRANSLATED,
  SAVE_CHANGES_BUTTON,
  CHOOSE_VOICES_BUTTON,
} from "./ComparePagePrompts";
import {
  ADD_SPEAKERS_PROMPT,
  CHOOSE_SPEAKER_DROPDOWN,
  CHANGE_BREAK_TIME,
  SAVE_SCRIPT_BUTTON,
  HELP_TOOLTIP_EDIT_TRANSCRIPT,
} from "./EditTranscriptPrompts";
import { NEW_PROJECT_BUTTON, SETTINGS_BUTTON } from "./HomepagePrompts";
import {
  CHOICE_PROMPT,
  CHOOSE_LANGUAGE_BUTTON,
  TRANSCRIPT_EDITOR_BUTTON,
  UPLOAD_BUTTON,
} from "./UploadPagePrompts";
import {
  SPEAKER_VOICE_DROPDOWN,
  ADJUST_VOICE_BUTTON,
  PREVIEW_VOICE_BUTTON,
  FILE_NAME_INPUT,
  FORMAT_DROPDOWN,
} from "./ConvertPagePrompts";
import { TRANSCRIPT_STATUS_PROMPT } from "./ProjectPagePrompts";

export const PROMPTS = {
  [ROUTES.HOME]: [SETTINGS_BUTTON, NEW_PROJECT_BUTTON],
  [ROUTES.UPLOAD]: [
    CHOICE_PROMPT,
    CHOOSE_LANGUAGE_BUTTON,
    UPLOAD_BUTTON,
    TRANSCRIPT_EDITOR_BUTTON,
  ],
  [ROUTES.EDIT_TRANSCRIPT]: [
    ADD_SPEAKERS_PROMPT,
    CHOOSE_SPEAKER_DROPDOWN,
    CHANGE_BREAK_TIME,
    SAVE_SCRIPT_BUTTON,
    HELP_TOOLTIP_EDIT_TRANSCRIPT,
  ],
  [ROUTES.COMPARE]: [
    CHOOSE_ORIGINAL_TEXT_DROPDOWN,
    REVERSE_TRANSLATED,
    SAVE_CHANGES_BUTTON,
    CHOOSE_VOICES_BUTTON,
  ],
  [ROUTES.CONVERT]: [
    SPEAKER_VOICE_DROPDOWN,
    ADJUST_VOICE_BUTTON,
    PREVIEW_VOICE_BUTTON,
    FILE_NAME_INPUT,
    FORMAT_DROPDOWN,
  ],
  [ROUTES.PROJECT]: [
    TRANSCRIPT_STATUS_PROMPT,
    // NEW_TRANSLATION_BUTTON,
    // TRANSLATION_STATUS_PROMPT,
    // DOWNLOAD_AUDIO_PROMPT,
  ],
};

export default PROMPTS;
