import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setLocation } from "redux/actions/Location";
import { GET_LOCATION } from "redux/constants/Location";

import LocationService from "services/LocationService";

export function* getLocationSaga() {
  yield takeEvery(GET_LOCATION, function* () {
    try {
      const response = yield call(LocationService.getLocation);
      yield put(setLocation(response.data));
    } catch (error) {
      console.log("Couldn't fetch your location. Falling back to Global");
      yield put(setLocation({ continentCode: "ALL" }));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getLocationSaga)]);
}
