import ApiService from "./ApiService";

const ENDPOINTS = {
  GET_SPEAKER_SAMPLES: "/text-to-speech/samples/",
  GET_VOICES: "/text-to-speech/voices",
  CONVERT_TEXT_TO_SPEECH: "/text-to-speech/",
  GET_VOICE_EXAMPLE: "/text-to-speech/voice/sample"
};

class TextToSpeechService extends ApiService {
  getSpeakersSample = (payload) => {
    return this.apiClient.get(`${ENDPOINTS.GET_SPEAKER_SAMPLES}${payload}`);
  };
  getVoices = () => {
    return this.apiClient.get(ENDPOINTS.GET_VOICES);
  };
  convertTextToSpeech = (payload) => {
    return this.apiClient.post(ENDPOINTS.CONVERT_TEXT_TO_SPEECH, payload);
  };
  getVoiceExample = (data) => {
    return this.apiClient.post(ENDPOINTS.GET_VOICE_EXAMPLE, data, {
      responseType: "blob",
    });
  };
}
export default new TextToSpeechService();
