import produce from "immer";
import { SET_INVOICES } from "redux/constants/Invoice";

const initState = {
  invoiceList: [],
};

const invoiceReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_INVOICES:
        draft.invoiceList = {
          count: payload?.total,
          current: payload?.page,
          size: payload?.size,
          results: payload?.items,
        };
        break;
      default:
        return state;
    }
  });
export default invoiceReducer;
