import ApiService from "./ApiService";

const ENDPOINTS = {
  ADD_NEWSLETTER: "/newsletter/",
};

class NewsletterService extends ApiService {
  addNewsletter = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.ADD_NEWSLETTER, payload);
  };
}

export default new NewsletterService();
