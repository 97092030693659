import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SEND_FORGOT_PASSWORD_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  SEND_FORGOT_PASSWORD_EMAIL_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  SIGNIN_ERROR,
  UPDATE_USER_DATA,
  GET_USER_INFO,
  SET_USER_INFO,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  SET_IS_VALIDATE,
  UPDATE_FILE_SETTINGS,
  GOOGLE_SIGNIN,
} from "../constants/Auth";

export const signIn = (payload) => {
  return {
    type: SIGNIN,
    payload,
  };
};

export const googleSignIn = (payload) => {
  return {
    type: GOOGLE_SIGNIN,
    payload,
  };
};

export const signInError = (payload) => {
  return {
    type: SIGNIN_ERROR,
    payload,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const sendForgotPasswordEmail = (payload, resetFields) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL,
    payload,
    resetFields,
  };
};

export const sendForgotPasswordEmailSuccess = (payload) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    payload,
  };
};

export const sendForgotPasswordEmailError = (payload) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_ERROR,
    payload,
  };
};

export const resetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const resetPasswordError = (payload) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload,
  };
};

export const getUserInfo = () => {
  return {
    type: GET_USER_INFO,
  };
};

export const setUserInfo = (payload) => {
  return {
    type: SET_USER_INFO,
    payload,
  };
};

export const updateUserData = (data, messageSuccess) => {
  return {
    type: UPDATE_USER_DATA,
    data,
    messageSuccess,
  };
};

export const changePassword = (data, messageSuccess, resetFields) => {
  return {
    type: CHANGE_PASSWORD,
    data,
    messageSuccess,
    resetFields,
  };
};

export const validateToken = (payload) => {
  return {
    type: VALIDATE_TOKEN,
    payload,
  };
};

export const setValidate = () => {
  return {
    type: SET_IS_VALIDATE,
  };
};

export const updateFileSettings = (
  data,
  messageSuccess,
  handleSuccessUpdate
) => {
  return {
    type: UPDATE_FILE_SETTINGS,
    data,
    messageSuccess,
    handleSuccessUpdate,
  };
};
