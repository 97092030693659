import ApiService from "./ApiService";

const ENDPOINTS = {
  CREATE_SUBSCRIPTION: "/paypal_subscription/",
  UNSUBSCRIBE: "/paypal_subscription/cancel/",
  RESUBSCRIBE: "/paypal_subscription/reactivate/",
  PREVIEW_INVOICE: "/paypal_subscription/preview",
  PREVIEW_UPDATE_SUBSCRIPTION: "/paypal_subscription/preview-update/",
  UPDATE_SUBSCRIPTION: "/paypal_subscription/",
  GET_SUBSCRIPTION: "/paypal_subscription/",
};

class PaypalSubscriptionService extends ApiService {
  createSubscription = async ({ subscription_id, plan_id }) => {
    return await this.apiClient.post(ENDPOINTS.CREATE_SUBSCRIPTION, {
      subscription_id,
      plan_id,
    });
  };

  unsubscribe = async (subscription_id) => {
    return await this.apiClient.post(ENDPOINTS.UNSUBSCRIBE + subscription_id);
  };

  resubscribe = async (subscription_id) => {
    return await this.apiClient.post(ENDPOINTS.RESUBSCRIBE + subscription_id);
  };

  updateSubscription = async (subscription_id) => {
    return await this.apiClient.put(
      ENDPOINTS.UPDATE_SUBSCRIPTION + subscription_id
    );
  };

  previewUpdateSubscription = async (payload) => {
    return await this.apiClient.put(
      ENDPOINTS.PREVIEW_UPDATE_SUBSCRIPTION + payload.subscription_id,
      payload
    );
  };

  previewInvoice = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.PREVIEW_INVOICE, payload);
  };

  getSubscription = async (subscription_id) => {
    return await this.apiClient.get(
      ENDPOINTS.GET_SUBSCRIPTION + subscription_id
    );
  };

}
export default new PaypalSubscriptionService();
