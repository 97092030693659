import { defineMessages } from "react-intl";

export const scope = "footer";

export default defineMessages({
  FAQText: {
    id: `${scope}.faq.text`,
    defaultMessage: "FAQ",
  },
  resourcesText: {
    id: `${scope}.resources.text`,
    defaultMessage: "Resources",
  },
  privacyText: {
    id: `${scope}.privacy.text`,
    defaultMessage: "Privacy Policy",
  },
  aboutText: {
    id: `${scope}.about.text`,
    defaultMessage: "About",
  },
  whatsNewText: {
    id: `${scope}.productChangeLog.text`,
    defaultMessage: "What's New",
  },
  privacyTermsText: {
    id: `${scope}.privacyTerms.text`,
    defaultMessage: "Terms of Service",
  },
  logInButton: {
    id: `${scope}.button.login`,
    defaultMessage: "Log in",
  },
  connect: {
    id: `${scope}.mobile.connect`,
    defaultMessage: "Connect with us",
  },
});
