import produce from "immer";
import {
  SET_TRANSLATION,
  SET_TRANSLATION_PER_PAGE,
} from "../constants/Translations";
import { SET_LANDING_TRANSLATION } from "redux/actions/Translations";

const initState = {
  translationList: [],
  translation: null,
  landingTranslation: null,
};

const translationReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_TRANSLATION_PER_PAGE:
        draft.translationList = {
          count: payload?.total,
          current: payload?.page,
          size: payload?.size,
          results: payload?.items,
        };
        break;
      case SET_TRANSLATION:
        draft.translation = payload;
        break;

      case SET_LANDING_TRANSLATION:
        draft.landingTranslation = payload;
        break;

      default:
        return state;
    }
  });
export default translationReducer;
