import { createSelector } from "reselect";
import reducers from "../reducers";

const selectUsersDomain = (state) => state.auth || reducers;

const makeSelectCurrentUser = () =>
  createSelector(selectUsersDomain, (substate) => substate.currentUser);

const makeSelectIsTokenValidate = () =>
  createSelector(selectUsersDomain, (substate) => substate.isValidateToken);

export { makeSelectCurrentUser, makeSelectIsTokenValidate };
