import { all, takeEvery, put, fork, call, delay } from "redux-saga/effects";
import {
  SEND_FORGOT_PASSWORD_EMAIL,
  SIGNOUT,
  SIGNIN,
  RESET_PASSWORD,
  SIGNUP,
  UPDATE_USER_DATA,
  GET_USER_INFO,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  UPDATE_FILE_SETTINGS,
  GOOGLE_SIGNIN,
} from "../constants/Auth";
import {
  showAuthMessage,
  signIn,
  setUserInfo,
  setValidate,
} from "../actions/Auth";
import { push, go } from "connected-react-router";
import AuthService from "services/AuthService";
import { ROUTES } from "routes";
import { message } from "antd";
import Analytics from "analytics";
import { getMySubscription } from "redux/actions/Subscription";
import { getPlan } from "redux/actions/Plan";

export function* signUp() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    try {
      const response = yield call(AuthService.signUp, payload);
      const data = new FormData();
      data.append("username", response.email);
      data.append("password", payload.password);
      yield put(signIn(data));
    } catch (error) {
      message.error(error.response.data.detail[0].msg, 3);
    }
  });
}

export function* signInSaga() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      yield call(AuthService.login, payload);
      yield put(push(ROUTES.HOME));
      yield put(go());
    } catch (error) {
      message.error(error.response.data.detail, 3);
    }
  });
}

export function* googleSignIn() {
  yield takeEvery(GOOGLE_SIGNIN, function* ({ payload }) {
    try {
      yield call(AuthService.googleLogin, payload);
      yield put(push(ROUTES.HOME));
      yield put(go());
    } catch (error) {
      console.log("error", error.message);
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      yield call(AuthService.logout);
      yield put(push(ROUTES.LOGIN));
      yield put(go());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* forgotPasswordEmailSend() {
  yield takeEvery(SEND_FORGOT_PASSWORD_EMAIL, function* ({ payload }) {
    try {
      const sendEmail = yield call(
        AuthService.sendForgotPasswordEmail,
        payload
      );
      yield delay(3, message.success(sendEmail.msg));
      yield put(push(ROUTES.LOGIN));
      yield put(go());
    } catch (err) {
      message.error(err.response.data.detail, 5);
    }
  });
}

export function* passwordReset() {
  yield takeEvery(RESET_PASSWORD, function* ({ payload }) {
    try {
      const response = yield call(AuthService.resetPassword, payload);
      yield delay(3, message.success(response.msg));
      yield put(push(ROUTES.LOGIN));
      yield put(go());
    } catch (error) {
      if ("detail" in error.response.data) {
        message.error(error.response.data.detail[0].msg, 3);
      } else {
        yield delay(3, message.error(error.response.data.message));
      }
    }
  });
}

export function* getUser() {
  yield takeEvery(GET_USER_INFO, function* () {
    try {
      const response = yield call(AuthService.getUserInfo);
      yield put(setUserInfo(response));
      Analytics.identify(response.id);
      Analytics.people.set({
        $email: response.email,
        $name: response.full_name,
      });
      if (response?.plan_id) {
        yield put(getMySubscription());
        yield put(getPlan(response?.plan_id));
      }
    } catch (error) {
      console.log("error", error?.message);
    }
  });
}

export function* changePassword() {
  yield takeEvery(
    CHANGE_PASSWORD,
    function* ({ data, messageSuccess, resetFields }) {
      try {
        yield call(AuthService.changePassword, data);
        message.success(messageSuccess, 3);
        resetFields();
      } catch (error) {
        message.error(
          "detail" in error.response.data
            ? error.response.data["detail"][0]["msg"]
            : JSON.parse(error.request.response).message,
          3
        );
      }
    }
  );
}

export function* updateUser() {
  yield takeEvery(UPDATE_USER_DATA, function* ({ data, messageSuccess }) {
    try {
      const response = yield call(AuthService.updateUser, data);
      yield put(setUserInfo(response));
      message.success(messageSuccess, 3);
    } catch (error) {
      message.error(JSON.parse(error.request.response).message, 3);
      console.log("error", error.request);
    }
  });
}

export function* validateToken() {
  yield takeEvery(VALIDATE_TOKEN, function* ({ payload }) {
    try {
      yield call(AuthService.validateToken, payload);
      yield put(setValidate());
    } catch (error) {
      yield delay(1, message.error(JSON.parse(error.request.response).message));
      yield put(push(ROUTES.FORGOT_PASSWORD));
      yield put(go());
    }
  });
}

export function* updateFileSettings() {
  yield takeEvery(
    UPDATE_FILE_SETTINGS,
    function* ({ data, messageSuccess, handleSuccessUpdate }) {
      try {
        const response = yield call(AuthService.updateFileSettings, data);
        yield put(setUserInfo(response.data));
        handleSuccessUpdate();
        message.success(messageSuccess, 2);
      } catch (error) {
        message.error(JSON.parse(error.request.response).message, 3);
        console.log("error", error.request);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(signInSaga),
    fork(googleSignIn),
    fork(signOut),
    fork(forgotPasswordEmailSend),
    fork(passwordReset),
    fork(signUp),
    fork(getUser),
    fork(updateUser),
    fork(changePassword),
    fork(validateToken),
    fork(updateFileSettings),
  ]);
}
