export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const GET_SUBSCRIPTION = "GET_SUBCSRIPTION";
export const GET_MY_SUBSCRIPTION = "GET_MY_SUBSCRIPTION";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const PREVIEW_UPDATE_SUBSCRIPTION = "PREVIEW_UPDATE_SUBSCRIPTION";
export const SET_PREVIEW = "SET_PREVIEW";
export const START_TRIAL = "START_TRIAL";
export const INVITE_USER = "INVITE_USER";
export const UNINVITE_USER = "UNINVITE_USER";
export const GET_INVITES = "GET_INVITES";
export const SET_INVITES = "SET_INVITES";
export const GET_CONNECTED_SUBSCRIPTIONS = "GET_CONNECTED_SUBSCRIPTIONS";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const REMOVE_CONNECTED_SUBSCRIPTION = "REMOVE_CONNECTED_SUBSCRIPTION";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const CHECK_COUPON = "CHECK_COUPON";
export const SET_COUPON = "SET_COUPON";
export const RESUBSCRIBE = "RESUBSCRIBE";
