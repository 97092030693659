import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { ROUTES } from "routes";
import {
  GET_SPEAKERS_SAMPLES,
  GET_VOICES,
  CONVERT_TEXT_TO_SPEECH,
  GET_VOICE_EXAMPLE,
} from "../constants/TextToSpeech";
import { setSpeakerSamples, setVoices } from "../actions/TextToSpeech";
import TextToSpeechService from "services/TextToSpeechService";
import { message } from "antd";

export function* getSpeakersSample() {
  yield takeEvery(GET_SPEAKERS_SAMPLES, function* ({ payload }) {
    try {
      const response = yield call(
        TextToSpeechService.getSpeakersSample,
        payload
      );
      yield put(setSpeakerSamples(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* getAllVoices() {
  yield takeEvery(GET_VOICES, function* () {
    try {
      const response = yield call(TextToSpeechService.getVoices);
      yield put(setVoices(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* convertTextToSpeech() {
  yield takeEvery(
    CONVERT_TEXT_TO_SPEECH,
    function* ({ payload, loader, projectId, history }) {
      try {
        yield call(TextToSpeechService.convertTextToSpeech, payload);
        // added history for help prompts event listener
        history.push(`${ROUTES.PROJECT}${projectId}`);
      } catch (error) {
        message.error({ content: error?.response?.data?.message }, 5);
        loader(false);
        console.log("error", error.response);
      }
    }
  );
}

export function* getVoiceExample() {
  yield takeEvery(GET_VOICE_EXAMPLE, function* ({ payload, playAudio }) {
    try {
      const response = yield call(TextToSpeechService.getVoiceExample, payload);
      var url = window.URL.createObjectURL(response.data);
      playAudio(url);
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getSpeakersSample),
    fork(getAllVoices),
    fork(convertTextToSpeech),
    fork(getVoiceExample),
  ]);
}
