import React from "react";
import { useHistory } from "react-router-dom";
import utils from "utils";
import { Button, Menu, Typography } from "antd";
import Flex from "components/shared-components/Flex";
import { ROUTES } from "routes";
import Analytics from "analytics";
import { useIntl } from "react-intl";
import translations from "components/layout-components/translations";
import LanguageSwitcher from "components/layout-components/LanguageSwitcher";
import { useIsMobile } from "helpers/useIsMobile";
import { MenuOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const TopNavSplash = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { isMobile } = useIsMobile();

  const headerLinks = [
    {
      name: formatMessage(translations.aboutText),
      link: "https://victormediagroup.notion.site/About-3981e0c4ed024183bfbd38a9278aece0",
    },
    {
      name: `${formatMessage(translations.FAQText)}s`,
      link: "https://victormediagroup.notion.site/FAQ-9ada55d77d55434f8d4bd8eb6e7390ef",
    },
    {
      name: formatMessage(translations.resourcesText),
      link: "https://victormediagroup.notion.site/Resources-4039fe2270b24dc8b8a53abd870f60ce",
    },
    ...(isMobile
      ? [
          {
            name: formatMessage(translations.privacyTermsText),
            link: "https://victormediagroup.notion.site/Terms-of-Service-328fdbd74fec44f28ff89279aa345df4",
          },
          {
            name: formatMessage(translations.privacyText),
            link: "https://victormediagroup.notion.site/Privacy-Policy-590f808c38974497a3264bbc493cbc5a",
          },
        ]
      : []),
  ];

  return (
    <div
      className={`top-nav ${utils.getColorContrast()}`}
      style={{ backgroundColor: isMobile ? "#0034A6" : "white", top: 0 }}
    >
      <Flex
        alignItems="center"
        justifyContent="end"
        className="vmg-nav"
        style={{ position: "relative" }}
      >
        <div className="mr-auto">
          <img
            src={isMobile ? "/img/logo-white.png" : "/img/logo.png"}
            alt={`logo`}
            style={{ height: "2.5rem", objectFit: "contain" }}
          />
        </div>
        <Menu
          mode="horizontal"
          selectable={false}
          overflowedIndicator={isMobile ? <MenuOutlined /> : undefined}
          style={
            isMobile
              ? {
                  background: "#0034A6",
                  color: "white",
                }
              : {}
          }
        >
          {headerLinks.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <a
                  id={`header-link-${index}`}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    item.onClick?.();
                    Analytics.track(`Click Link`, {
                      Page: item.name,
                      Link: item.link,
                    });
                  }}
                  key={index}
                  className="text-lightgray"
                >
                  <span>
                    <Text>{item.name}</Text>
                  </span>
                </a>
              </Menu.Item>
            );
          })}
        </Menu>
        {!isMobile && (
          <Flex alignItems="center">
            <Button
              className="rounded-pill pl-4 pr-4 pt-2 pb-2 bg-primary"
              size="middle"
              type="primary"
              data-cy="loginButton"
              id="login-button"
              onClick={() => history.push(ROUTES.LOGIN)}
            >
              {formatMessage(translations.logInButton)}
            </Button>
          </Flex>
        )}
        <LanguageSwitcher />
      </Flex>
    </div>
  );
};

export default TopNavSplash;
