import produce from "immer";
import { SET_SPEAKERS_SAMPLES, SET_VOICES } from "../constants/TextToSpeech";

const initState = {
  speakerSamples: [],
  voices: [],
};

const textToSpeechReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_SPEAKERS_SAMPLES:
        draft.speakerSamples = payload;
        break;
      case SET_VOICES:
        draft.voices = payload;
        break;
      default:
        return state;
    }
  });
export default textToSpeechReducer;
