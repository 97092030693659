import produce from "immer";
import { SET_PAYPAL_SUBSCRIPTION } from "redux/constants/PaypalSubscription";
import {
  SET_PREVIEW,
  SET_SUBSCRIPTION,
  SET_INVITES,
  SET_SUBSCRIPTIONS,
  SET_COUPON,
} from "redux/constants/Subscription";

const initState = {
  subscription: null,
  preview: null,
  invites: [],
  subscriptionList: [],
  coupon: null,
  paypalSubscription: null
};

const subscriptionReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_SUBSCRIPTION:
        draft.subscription = payload;
        break;
      case SET_PREVIEW:
        draft.preview = payload;
        break;
      case SET_INVITES:
        draft.invites = payload;
        break;
      case SET_SUBSCRIPTIONS:
        draft.subscriptionList = payload;
        break;
      case SET_COUPON:
        draft.coupon = payload;
        break;
      case SET_PAYPAL_SUBSCRIPTION:
          draft.paypalSubscription = payload;
          break;
      default:
        return state;
    }
  });
export default subscriptionReducer;
