import produce from "immer";
import {
    SET_ALL_LANGUAGES
} from "../constants/UploadFile";

const initState = {
  languages: []
};

const uploadFileReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_LANGUAGES:
        draft.languages = payload;
        break;
      default:
        return state;
    }
  });
  
export default uploadFileReducer;
