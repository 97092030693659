import Analytics from "analytics";
export const footerOptionsList = (optionsList) => {
  return optionsList.map((item, index) => (
    <a
      id={`footer-link-${index}`}
      href={item.link}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => {
        Analytics.track(`Click Link`, {
          Page: item.name,
          Link: item.link,
        });
      }}
      key={index}
    >
      {index > 0 && " - "}
      {item.name}
    </a>
  ));
};
