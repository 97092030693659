import { SIDE_NAV_LIGHT, NAV_TYPE_TOP } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Victor Voice";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/dashboard";
export const AUTH_PREFIX_PATH = "/auth";
export const MIXPANEL_ACTIVE = env.MIXPANEL_ACTIVE;
export const MIXPANEL_TOKEN = env.MIXPANEL_TOKEN;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_TOP,
  topNavColor: "#ffffff",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
};
