import languageReducer from "components/util-components/LanguageProvider/reducer";
import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import transcriptReducer from "./Transcript";
import textToSpeechReducer from "./TextToSpeech";
import { connectRouter } from "connected-react-router";
import uploadFileReducer from "./UploadFile";
import projectReducer from "./Projects";
import translationReducer from "./Translations";
import planReducer from "./Plan";
import subscriptionReducer from "./Subscription";
import invoiceReducer from "./Invoice";
import cardReducer from "./Card";
import promptReducer from "./PromptReducer";
import locationReducer from "./Location";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    theme: Theme,
    auth: Auth,
    language: languageReducer,
    router: connectRouter(history),
    transcript: transcriptReducer,
    textToSpeech: textToSpeechReducer,
    uploadFile: uploadFileReducer,
    project: projectReducer,
    translation: translationReducer,
    plan: planReducer,
    subscription: subscriptionReducer,
    invoice: invoiceReducer,
    card: cardReducer,
    prompt: promptReducer,
    location: locationReducer,
  });
