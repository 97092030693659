export const GET_TRANSCRIPT_PER_PAGE = "GET_TRANSCRIPT_PER_PAGE";
export const SET_TRANSCRIPT_PER_PAGE = "SET_TRANSCRIPT_PER_PAGE";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const GET_TRANSCRIPT = "GET_TRANSCRIPT";
export const SET_TRANSCRIPT = "SET_TRANSCRIPT";
export const CREATE_TRANSCRIPT = "CREATE_TRANSCRIPT";
export const ADD_NEW_SPEAKER = "ADD_NEW_SPEAKER";
export const DELETE_TRANSCRIPT = "DELETE_TRANSCRIPT";
export const UPDATE_TRANSCRIPT = "UPDATE_TRANSCRIPT";
export const RENAME_TRANSCRIPT = "RENAME_TRANSCRIPT";
export const DOWNLOAD_TRANSCRIPT_FILE = "DOWNLOAD_TRANSCRIPT_FILE";
