import produce from "immer";
import { SET_CARD } from "redux/constants/Card";

const initState = {
  card: null,
};

const cardReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CARD:
        draft.card = payload;
        break;
      default:
        return state;
    }
  });
export default cardReducer;
