import { message } from "antd";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  DELETE_PROJECT,
  GET_PROJECT,
  GET_PROJECTS_PER_PAGE,
  NUMBER_PER_PAGE,
  UPDATE_PROJECT,
} from "../constants/Projects";
import ProjectService from "services/ProjectService";
import {
  setProjectsPerPage,
  setProject,
  getProjectsPerPage,
} from "redux/actions/Projects";
import { setSpeakerSamples, setVoices } from "redux/actions/TextToSpeech";
import { setTranscriptPerPage } from "redux/actions/Transcript";

export function* getProjectPerPage() {
  yield takeEvery(GET_PROJECTS_PER_PAGE, function* ({ payload }) {
    try {
      const response = yield call(ProjectService.getProjectsPerPage, payload);
      yield put(setProjectsPerPage(response.data));
      yield put(setSpeakerSamples([]));
      yield put(setVoices([]));
      yield put(setProject(null));
      yield put(setTranscriptPerPage([]));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* getProject() {
  yield takeEvery(GET_PROJECT, function* ({ payload, redirect }) {
    try {
      yield put(setProject(null));
      yield put(setTranscriptPerPage([]));
      const response = yield call(ProjectService.getProject, payload);
      yield put(setProject(response.data));
    } catch (error) {
      console.log("error: ", error.response.data.message);
      message.error(error.response.data.message, 3).then(() => {
        redirect();
      });
    }
  });
}

export function* deleteProject() {
  yield takeEvery(DELETE_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(ProjectService.deleteProject, payload.id);
      message.success(response.data.message, 3);
      yield put(
        getProjectsPerPage({
          orderBy: payload.orderBy,
          page: payload.page,
          perPage: NUMBER_PER_PAGE,
          filter: payload.filter,
        })
      );
    } catch (error) {
      console.log("error: ", error.response.data.message);
    }
  });
}

export function* updateProject() {
  yield takeEvery(UPDATE_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(ProjectService.updateProject, {
        projectId: payload.id,
        name: payload.name,
      });
      message.success(response.data.message, 3);
      yield put(
        getProjectsPerPage({
          orderBy: payload.orderBy,
          page: payload.page,
          perPage: NUMBER_PER_PAGE,
          filter: payload.filter,
        })
      );
      payload.setLoaderActive(false);
    } catch (error) {
      console.log(error);
      console.log("error: ", error?.response?.data?.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProjectPerPage),
    fork(getProject),
    fork(deleteProject),
    fork(updateProject),
  ]);
}
