import { GET_LOCATION, SET_LOCATION } from "redux/constants/Location";

export const getLocation = () => ({
  type: GET_LOCATION,
});

export const setLocation = (payload) => ({
  type: SET_LOCATION,
  payload,
});
