import { GET_PLANS, GET_PLAN, SET_PLAN, SET_PLANS } from "redux/constants/Plan";

export const getPlans = (payload) => ({
  type: GET_PLANS,
  payload,
});

export const getPlan = (payload) => ({
  type: GET_PLAN,
  payload,
});

export const setPlans = (payload) => ({
  type: SET_PLANS,
  payload,
});

export const setPlan = (payload) => ({
  type: SET_PLAN,
  payload,
});
