import { DOWNLOAD_TRANSLATE_FILE } from "redux/constants/Translate";
import {
  DELETE_TRANSLATION,
  RENAME_TRANSLATION,
  RE_EDIT_TRANSLATION,
} from "redux/constants/Translations";

export const GET_TRANSLATION = "GET_TRANSLATION";
export const SET_TRANSLATION = "SET_TRANSLATION";
export const GET_LANDING_TRANSLATION = "GET_LANDING_TRANSLATION";
export const GET_TRANSLATION_PER_PAGE = "GET_TRANSLATION_PER_PAGE";
export const SET_TRANSLATION_PER_PAGE = "SET_TRANSLATION_PER_PAGE";
export const SET_LANDING_TRANSLATION = "SET_LANDING_TRANSLATION";

export const getTranslation = (payload, redirect) => {
  return {
    type: GET_TRANSLATION,
    payload,
    redirect,
  };
};

export const setTranslation = (payload) => {
  return {
    type: SET_TRANSLATION,
    payload,
  };
};

export const getTranslationPerPage = (payload) => {
  return {
    type: GET_TRANSLATION_PER_PAGE,
    payload,
  };
};

export const getLandingTranslation = (payload) => {
  return {
    type: GET_LANDING_TRANSLATION,
    payload,
  };
};

export const setLandingTranslation = (payload) => {
  return {
    type: SET_LANDING_TRANSLATION,
    payload,
  };
};

export const setTranslationPerPage = (payload) => {
  return {
    type: SET_TRANSLATION_PER_PAGE,
    payload,
  };
};

export const reEditTranslation = (
  payload,
  setLoaderActive,
  redirectToProject
) => {
  return {
    type: RE_EDIT_TRANSLATION,
    payload,
    setLoaderActive,
    redirectToProject,
  };
};

export const deleteTranslation = (payload) => {
  return {
    type: DELETE_TRANSLATION,
    payload,
  };
};

export const renameTranslation = (payload) => {
  return {
    type: RENAME_TRANSLATION,
    payload,
  };
};

export const downloadTranslateFile = (payload, setLoaderActive) => {
  return {
    type: DOWNLOAD_TRANSLATE_FILE,
    payload,
    setLoaderActive,
  };
};
