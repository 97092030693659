import axios from "axios";
import { message } from "antd";
import { API_BASE_URL } from "configs/AppConfig";
import AuthService from "./AuthService";

class HttpService {
  constructor(options = {}) {
    this.client = axios.create(options);
    this.addRequestInterceptor();
    this.client.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
    this.unauthorizedCallback = () => {};
  }

  attachHeaders(headers) {
    Object.assign(this.client.defaults.headers, headers);
  }

  removeHeaders(headerKeys) {
    headerKeys.forEach((key) => delete this.client.defaults.headers[key]);
  }

  handleSuccessResponse(response) {
    return response;
  }

  handleErrorResponse(error) {
    const { status } = error.response;
    switch (status) {
      case 401: {
        AuthService.destroySession();
        message.warning("Your session has expired", 5);
        window.location.reload();
        break;
      }
      default:
        break;
    }

    return Promise.reject(error);
  }

  addRequestInterceptor = (callback) => {
    return this.client.interceptors.request.use(callback);
  };

  removeRequestInterceptor = (interceptorId) => {
    this.client.interceptors.request.eject(interceptorId);
  };

  setUnauthorizedCallback(callback) {
    this.unauthorizedCallback = callback;
  }
}

const options = {
  baseURL: API_BASE_URL,
};
const httpService = new HttpService(options);

export default httpService;
