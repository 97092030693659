import { Modal } from "antd";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { getUserInfo } from "redux/actions/Auth";
import {
  setPreview,
  setSubscription,
  setSubscriptions,
  getConnectedSubscriptions,
  setCoupon,
} from "redux/actions/Subscription";
import {
  CREATE_CUSTOMER,
  CREATE_SUBSCRIPTION,
  GET_MY_SUBSCRIPTION,
  PREVIEW_UPDATE_SUBSCRIPTION,
  START_TRIAL,
  UNSUBSCRIBE,
  UPDATE_SUBSCRIPTION,
  INVITE_USER,
  GET_CONNECTED_SUBSCRIPTIONS,
  REMOVE_CONNECTED_SUBSCRIPTION,
  ACCEPT_INVITE,
  CHECK_COUPON,
  RESUBSCRIBE,
} from "redux/constants/Subscription";
import SubscriptionService from "services/SubscriptionService";

export function* createSubscriptionSaga() {
  yield takeEvery(CREATE_SUBSCRIPTION, function* ({ payload }) {
    try {
      const response = yield call(
        SubscriptionService.createSubscription,
        payload
      );
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* createCustomerSaga() {
  yield takeEvery(CREATE_CUSTOMER, function* ({ payload }) {
    try {
      const response = yield call(SubscriptionService.createCustomer, payload);
      yield put(setSubscription(response.data));
    } catch (error) {
      Modal.error({ title: "Error", content: error.response.data.detail });
      yield put(setSubscription(null));
      yield put(setCoupon(null));
      console.log("error", error.response);
    }
  });
}

export function* getUserSubscription() {
  yield takeEvery(GET_MY_SUBSCRIPTION, function* () {
    try {
      const response = yield call(SubscriptionService.getUserSubscription);
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* unsubscribeSaga() {
  yield takeEvery(UNSUBSCRIBE, function* ({ payload }) {
    try {
      const response = yield call(SubscriptionService.unsubscribe, payload);
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* resubscribeSaga() {
  yield takeEvery(RESUBSCRIBE, function* ({ payload }) {
    try {
      const response = yield call(SubscriptionService.resubscribe, payload);
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* previewUpdate() {
  yield takeEvery(PREVIEW_UPDATE_SUBSCRIPTION, function* ({ payload }) {
    try {
      const response = yield call(SubscriptionService.previewUpdate, payload);
      yield put(setPreview(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* updateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, function* ({ payload }) {
    try {
      const response = yield call(
        SubscriptionService.updateSubscription,
        payload
      );
      yield put(setSubscription(response.data));
      yield put(getUserInfo());
    } catch (error) {
      Modal.error({ title: "Error", content: error.response.data.detail });
      console.log("error", error.response);
    }
  });
}

export function* startTrialSaga() {
  yield takeEvery(START_TRIAL, function* ({ redirect }) {
    try {
      yield call(SubscriptionService.startTrial);
      yield put(getUserInfo());
      setTimeout(() => {
        redirect.method(redirect.location);
      }, 100);
    } catch (error) {
      Modal.error({
        title: "Error",
        content: error.response.data.detail ?? error.response.data.message,
      });
      console.log("error", error.response);
    }
  });
}

export function* inviteUserSaga() {
  yield takeEvery(INVITE_USER, function* ({ payload }) {
    try {
      yield call(SubscriptionService.inviteUser, payload);
      Modal.success({ content: "Sucessfully Invited User" });
    } catch (error) {
      Modal.error({
        content: `An error occurred during the invitation process! ${error?.response?.data?.detail}`,
      });
      console.log("error", error.response);
    }
  });
}

export function* acceptInviteSaga() {
  yield takeEvery(ACCEPT_INVITE, function* ({ payload }) {
    try {
      yield call(SubscriptionService.acceptInvite, payload);
      Modal.success({ content: "Sucessfully Accepted Invite" });
    } catch (error) {
      Modal.error({
        content: `An error occurred while accepting invite. ${error?.response?.data?.detail}`,
      });
      console.log("error", error.response);
    }
  });
}

export function* getConnectedSubscriptionsSaga() {
  yield takeEvery(GET_CONNECTED_SUBSCRIPTIONS, function* () {
    try {
      const response = yield call(
        SubscriptionService.getConnectedSubscriptions
      );
      yield put(setSubscriptions(response.data));
    } catch (error) {
      console.log("error", error);
    }
  });
}

export function* removeConnectedSubscriptionSaga() {
  yield takeEvery(REMOVE_CONNECTED_SUBSCRIPTION, function* ({ payload }) {
    try {
      yield call(SubscriptionService.removeConnectedSubscription, {
        subscription_id: payload,
      });
      yield put(getConnectedSubscriptions());
    } catch (error) {
      yield put(setSubscriptions([]));
      yield put(getConnectedSubscriptions());
      console.log("error", error);
    }
  });
}

export function* checkCoupon() {
  yield takeEvery(CHECK_COUPON, function* ({ payload }) {
    try {
      const response = yield call(SubscriptionService.checkCoupon, payload);

      yield put(setCoupon(response.data));
      Modal.success({ content: "Coupon Applied!" });
    } catch (error) {
      Modal.error({ content: error?.response?.data?.detail });
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createSubscriptionSaga),
    fork(createCustomerSaga),
    fork(getUserSubscription),
    fork(unsubscribeSaga),
    fork(previewUpdate),
    fork(updateSubscription),
    fork(startTrialSaga),
    fork(inviteUserSaga),
    fork(getConnectedSubscriptionsSaga),
    fork(removeConnectedSubscriptionSaga),
    fork(acceptInviteSaga),
    fork(checkCoupon),
    fork(resubscribeSaga),
  ]);
}
