import ApiService from "./ApiService";

const ENDPOINTS = {
  GET_CARD: "/cards/",
  CHANGE_CARD: "/cards/change",
};

class CardService extends ApiService {
  getUserCard = async () => {
    return await this.apiClient.get(ENDPOINTS.GET_CARD);
  };

  changeCard = async ({ paymentMethod, oldPaymentMethod }) => {
    return await this.apiClient.post(ENDPOINTS.CHANGE_CARD, {
      old_payment_method: oldPaymentMethod,
      new_payment_method: paymentMethod,
    });
  };
}

export default new CardService();
