import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { env } from "configs/EnvironmentConfig";
import { useDispatch } from "react-redux";
import { getLocation } from "redux/actions/Location";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocation());
  }, []);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <PayPalScriptProvider
          options={{
            "client-id": env.PAYPAL_CLIENT_ID,
            "disable-funding": "card",
            components: "buttons",
            intent: "subscription",
            vault: true,
          }}
        >
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </PayPalScriptProvider>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
