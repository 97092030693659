import { Modal } from "antd";
import { takeEvery, put, call, fork, all } from "redux-saga/effects";
import { getUserInfo } from "redux/actions/Auth";
import { setPaypalSubscription } from "redux/actions/PaypalSubscription";
import {
  setSubscription,
  setCoupon,
  setPreview,
} from "redux/actions/Subscription";
import {
  CREATE_SUBSCRIPTION_PAYPAL,
  GET_SUBSCRIPTION_PAYPAL,
  PREVIEW_INVOICE_PAYPAL,
  PREVIEW_UPDATE_SUBSCRIPTION_PAYPAL,
  RESUBSCRIBE_PAYPAL,
  UNSUBSCRIBE_PAYPAL,
  UPDATE_SUBSCRIPTION_PAYPAL,
} from "redux/constants/PaypalSubscription";
import PaypalSubscriptionService from "services/PaypalSubscriptionService";

export function* createSubscriptionSaga() {
  yield takeEvery(CREATE_SUBSCRIPTION_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.createSubscription,
        payload
      );
      yield put(setSubscription(response.data));
    } catch (error) {
      Modal.error({ title: "Error", content: error.response.data.detail });
      yield put(setSubscription(null));
      yield put(setCoupon(null));
      console.log("error", error.response);
    }
  });
}

export function* unsubscribeSaga() {
  yield takeEvery(UNSUBSCRIBE_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.unsubscribe,
        payload
      );
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* resubscribeSaga() {
  yield takeEvery(RESUBSCRIBE_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.resubscribe,
        payload
      );
      yield put(setSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* previewInvoice() {
  yield takeEvery(PREVIEW_INVOICE_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.previewInvoice,
        payload
      );
      yield put(setPreview(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* previewUpdateSubscription() {
  yield takeEvery(PREVIEW_UPDATE_SUBSCRIPTION_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.previewUpdateSubscription,
        payload
      );
      yield put(setPreview(response.data));
    } catch (error) {
      Modal.error({ title: "Error", content: error.response.data.detail });
      console.log("error", error.response);
    }
  });
}
export function* updateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.updateSubscription,
        payload
      );
      yield put(setSubscription(response.data));
      yield put(getUserInfo());
    } catch (error) {}
  });
}

export function* getUserSubscription() {
  yield takeEvery(GET_SUBSCRIPTION_PAYPAL, function* ({ payload }) {
    try {
      const response = yield call(
        PaypalSubscriptionService.getSubscription,
        payload
      );
      yield put(setPaypalSubscription(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createSubscriptionSaga),
    fork(unsubscribeSaga),
    fork(resubscribeSaga),
    fork(previewUpdateSubscription),
    fork(updateSubscription),
    fork(previewInvoice),
    fork(getUserSubscription),
  ]);
}
