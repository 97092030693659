import {
  CREATE_CUSTOMER,
  CREATE_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  GET_MY_SUBSCRIPTION,
  SET_SUBSCRIPTION,
  UNSUBSCRIBE,
  UPDATE_SUBSCRIPTION,
  PREVIEW_UPDATE_SUBSCRIPTION,
  SET_PREVIEW,
  START_TRIAL,
  INVITE_USER,
  UNINVITE_USER,
  GET_INVITES,
  SET_INVITES,
  GET_CONNECTED_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS,
  REMOVE_CONNECTED_SUBSCRIPTION,
  ACCEPT_INVITE,
  CHECK_COUPON,
  SET_COUPON,
  RESUBSCRIBE,
} from "redux/constants/Subscription";

export const createSubscription = (payload) => ({
  type: CREATE_SUBSCRIPTION,
  payload,
});

export const setSubscription = (payload) => ({
  type: SET_SUBSCRIPTION,
  payload,
});

export const getSubscription = (payload) => ({
  type: GET_SUBSCRIPTION,
  payload,
});
export const getMySubscription = () => ({
  type: GET_MY_SUBSCRIPTION,
});

export const createCustomer = (payload) => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const unsubscribe = (payload) => ({
  type: UNSUBSCRIBE,
  payload,
});

export const resubscribe = (payload) => ({
  type: RESUBSCRIBE,
  payload,
});

export const updateSubscription = (payload) => ({
  type: UPDATE_SUBSCRIPTION,
  payload,
});

export const previewUpdate = (payload) => ({
  type: PREVIEW_UPDATE_SUBSCRIPTION,
  payload,
});

export const setPreview = (payload) => ({
  type: SET_PREVIEW,
  payload,
});

export const startTrial = (redirect) => ({
  type: START_TRIAL,
  redirect,
});

export const inviteUser = (payload) => ({
  type: INVITE_USER,
  payload,
});

export const unInviteUser = (payload) => ({
  type: UNINVITE_USER,
  payload,
});

export const getInvites = () => ({
  type: GET_INVITES,
});

export const acceptInvite = (payload) => ({
  type: ACCEPT_INVITE,
  payload,
});

export const setInvites = (payload) => ({
  type: SET_INVITES,
  payload,
});

export const getConnectedSubscriptions = () => ({
  type: GET_CONNECTED_SUBSCRIPTIONS,
});

export const setSubscriptions = (payload) => ({
  type: SET_SUBSCRIPTIONS,
  payload,
});

export const removeConnectedSubscription = (payload) => ({
  type: REMOVE_CONNECTED_SUBSCRIPTION,
  payload,
});

export const checkCoupon = (payload) => ({
  type: CHECK_COUPON,
  payload,
});

export const setCoupon = (payload) => ({
  type: SET_COUPON,
  payload,
});
