import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { message } from "antd";
import { TRANSLATE_TEXT } from "../constants/Translate";
import TranslateService from "services/TranslateService";
import { getTranslationPerPage } from "redux/actions/Translations";
import { setPromptList, transition } from "redux/actions/Prompt";
import { TRANSLATION_STATUS_PROMPT } from "components/shared-components/HelpPrompt/constants/ProjectPagePrompts";

export function* translateText() {
  yield takeEvery(
    TRANSLATE_TEXT,
    function* ({ payload, setLoaderActive, refreshTranslations }) {
      try {
        yield call(TranslateService.translateText, payload);
        message.success("Succesfully created translation");
        yield put(getTranslationPerPage(refreshTranslations));
        setLoaderActive(false);
        yield put(setPromptList([TRANSLATION_STATUS_PROMPT]));
        yield put(transition(0));
      } catch (error) {
        message.error(error.response?.data?.detail?.[0]?.msg, 3);
        setLoaderActive(false);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(translateText)]);
}
