import produce from "immer";
import { SET_PROJECTS_PER_PAGE, SET_PROJECT } from "redux/constants/Projects";

const initState = {
  projectList: [],
  project: null,
};

const projectReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_PROJECTS_PER_PAGE:
        draft.projectList = {
          count: payload?.total,
          current: payload?.page,
          size: payload?.size,
          results: payload?.items,
        };
        break;
      case SET_PROJECT:
        draft.project = payload;
        break;
      default:
        return state;
    }
  });
export default projectReducer;
