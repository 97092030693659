import produce from "immer";
import { SET_LOCATION } from "redux/constants/Location";

const initState = {
  location: null,
};

const locationReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_LOCATION:
        draft.location = payload;
        break;
      default:
        return state;
    }
  });

export default locationReducer;
