import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { PrivateRoute } from "shared/PrivateRoute";
import { PublicRoute } from "shared/PublicRoute";
import HelpPrompt from "components/shared-components/HelpPrompt";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/splash`}
          component={lazy(() =>
            import(`../auth-views/authentication/splash-page`)
          )}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./HomePage`))}
          exact
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./SettingsPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/convert/:translationId`}
          component={lazy(() => import(`./SpeakerPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/upload`}
          component={lazy(() => import(`./UploadFilePage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/compare/:translationId`}
          component={lazy(() => import(`./ComparePage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/projects/:projectId`}
          component={lazy(() => import(`./ProjectPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/transcript/:transcriptId`}
          component={lazy(() => import(`./TranscriptEditPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/prices/`}
          component={lazy(() => import(`./PricingPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/checkout/:planId`}
          component={lazy(() =>
            import(`components/stripe-components/Checkout`)
          )}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
      <HelpPrompt
        renderContent={() => {
          return <div style={{ display: "none" }}></div>;
        }}
        promptName="empty"
      >
        <div style={{ display: "none" }}></div>
      </HelpPrompt>
    </Suspense>
  );
};

export default React.memo(AppViews);
