import ApiService from "./ApiService";

const ENDPOINTS = {
  TRANSLATE_TEXT: "translate/",
};

class TranslateService extends ApiService {
  translateText = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.TRANSLATE_TEXT, payload);
  };
}
export default new TranslateService();
