import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setPlans, setPlan } from "redux/actions/Plan";
import { GET_PLANS, GET_PLAN } from "redux/constants/Plan";
import PlanService from "services/PlanService";

export function* getPlansSaga() {
  yield takeEvery(GET_PLANS, function* ({ payload }) {
    try {
      const response = yield call(PlanService.getPlans, payload);
      yield put(setPlans(response.data));
    } catch (error) {
      console.log("error", error);
    }
  });
}

export function* getSinglePlan() {
  yield takeEvery(GET_PLAN, function* ({ payload }) {
    try {
      const response = yield call(PlanService.getPlan, payload);
      yield put(setPlan(response.data));
    } catch (error) {
      console.log("error", error.response);
      console.log("error object", error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getPlansSaga), fork(getSinglePlan)]);
}
