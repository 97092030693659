import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Typography } from "antd";
import { changeLocale } from "components/util-components/LanguageProvider/actions";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLocale } from "components/util-components/LanguageProvider/selectors";
import { languages } from "i18n";

const LanguageSwitcher = () => {
  const locale = useSelector(makeSelectLocale());

  const [language, setLanguage] = useState(null);
  const dispatch = useDispatch();

  const changeLanguage = ({ key }) => {
    dispatch(changeLocale(languages[key].lang));
  };

  useEffect(() => {
    setLanguage(languages[locale]);
  }, [locale]);

  const overlay = (
    <Menu languages={Object.values(languages)} onClick={changeLanguage}>
      {Object.values(languages).map((item) => (
        <Menu.Item
          key={item.lang}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Text className="text-gray-light">
            {item.label}
          </Typography.Text>
          <img alt="menu-img" width="32px" src={item.icon} />
        </Menu.Item>
      ))}
    </Menu>
  );

  if (language)
    return (
      <Dropdown menu={{ languages }} overlay={overlay}>
        <img
          alt="header-img"
          width="32px"
          src={language.icon}
          style={{ paddingLeft: "5px" }}
        />
      </Dropdown>
    );
  return (
    <Typography.Text className="text-gray-light">Loading...</Typography.Text>
  );
};

export default LanguageSwitcher;
