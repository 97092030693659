import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setInvoices } from "redux/actions/Invoice";
import { GET_INVOICES } from "redux/constants/Invoice";
import InvoiceService from "services/InvoiceService";

export function* getInvoicesPerPage() {
  yield takeEvery(GET_INVOICES, function* ({ payload }) {
    try {
      const response = yield call(InvoiceService.getInvoicesPerPage, payload);
      yield put(setInvoices(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getInvoicesPerPage)]);
}
