import ApiService from "./ApiService";

const ENDPOINTS = {
  GET_INVOICES: "/invoice/?",
};

class InvoiceService extends ApiService {
  getInvoicesPerPage = async ({ page, perPage, filter = null }) => {
    const url = new URLSearchParams();
    if (filter !== null) {
      url.append("filter", filter);
    }
    url.append("page", page);
    url.append("size", perPage);

    return await this.apiClient.get(ENDPOINTS.GET_INVOICES + url.toString());
  };
}

export default new InvoiceService();
