import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_PROJECTS: "/projects/?order_by=%s&page=%s&size=%s&filter=%s",
  GET_PROJECT: "/projects/",
};

class ProjectService extends ApiService {
  getProjectsPerPage = async ({ orderBy, page, perPage, filter }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_PROJECTS, orderBy, page, perPage, filter)
    );
  };

  getProject = (projectId) => {
    return this.apiClient.get(`${ENDPOINTS.GET_PROJECT}${projectId}`);
  };

  deleteProject = (projectId) => {
    return this.apiClient.delete(`${ENDPOINTS.GET_PROJECT}${projectId}`);
  };

  updateProject = ({ projectId, name }) => {
    return this.apiClient.put(`${ENDPOINTS.GET_PROJECT}${projectId}`, {
      name: name,
    });
  };
}

export default new ProjectService();
