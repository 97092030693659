import { GET_CARD, SET_CARD, CHANGE_CARD } from "redux/constants/Card";

export const getCard = () => ({
  type: GET_CARD,
});

export const setCard = (payload) => ({
  type: SET_CARD,
  payload,
});

export const changeCard = (payload) => ({
  type: CHANGE_CARD,
  payload,
});
