import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import ApiService from "./ApiService";
import jwtDecode from "jwt-decode";
import { format } from "util";

const ENDPOINTS = {
  LOGIN: "/auth/token",
  GOOGLE_LOGIN: "/auth/google_login",
  FORGOT_PASSWORD: "/auth/forget-password/",
  RESET_PASSWORD: "/auth/reset-password",
  SIGN_UP: "/user/",
  USER_INFO: "/user/me",
  UPDATE_USER: "/user/update",
  UPDATE_FILE_SETTINGS: "/user/settings",
  REFRESH_TOKEN: "/auth/refresh_token",
  CHANGE_PASSWORD: "/user/change_password",
  VALIDATE_TOKEN: "/auth/forgot_password_token/%s/validate",
};

class AuthService extends ApiService {
  constructor() {
    super();
    this.init();
  }

  init = () => {
    const token = this.getToken();
    const user = this.getUser();

    if (token && user) {
      this.api.addRequestInterceptor(this.checkTokenExpiration);
      this.setAuthorizationHeader();
      this.api.setUnauthorizedCallback(this.destroySession.bind(this));
    }
  };

  setAuthorizationHeader = () => {
    const token = this.getToken();
    if (token) {
      this.api.attachHeaders({
        Authorization: `Bearer ${token}`,
      });
    }
  };

  refreshToken = async (prevToken) => {
    const headers = {
      Authorization: `Bearer ${prevToken}`,
    };
    const newToken = await this.apiClient.get(ENDPOINTS.REFRESH_TOKEN, {
      headers: headers,
    });
    setLocalStorageItem("access_token", newToken.data.access_token);
    return newToken.data.access_token;
  };

  checkTokenExpiration = async (request) => {
    if (request.url === ENDPOINTS.REFRESH_TOKEN) {
      return request;
    }

    const token = this.getToken();

    if (token && Date.now() / 1000 >= jwtDecode(token).exp) {
      const newToken = await this.refreshToken(this.getRefreshToken());
      request.headers.Authorization = `Bearer ${newToken}`;
      this.setAuthorizationHeader();
      return request;
    }

    return request;
  };

  createSession = (user) => {
    setLocalStorageItem("access_token", user["access_token"]);
    setLocalStorageItem("refresh_token", user["refresh_token"]);
    this.setAuthorizationHeader();
  };

  destroySession = () => {
    localStorage.clear();
    this.api.removeHeaders(["Authorization"]);
  };

  getToken = () => {
    const token = getLocalStorageItem("access_token");
    return token ? token : undefined;
  };

  getRefreshToken = () => {
    const token = getLocalStorageItem("refresh_token");
    return token ? token : undefined;
  };

  getUser = () => {
    const user = getLocalStorageItem("access_token");
    return user;
  };

  getUserInfo = async () => {
    const { data } = await this.apiClient.get(`${ENDPOINTS.USER_INFO}`);
    return data;
  };

  signUp = async (signUpData) => {
    const { data } = await this.apiClient.post(ENDPOINTS.SIGN_UP, signUpData);
    return data;
  };

  login = async (loginData) => {
    const { data } = await this.apiClient.post(ENDPOINTS.LOGIN, loginData);
    this.createSession(data);
    return data;
  };

  googleLogin = async (payload) => {
    const { data } = await this.apiClient.post(ENDPOINTS.GOOGLE_LOGIN, payload);
    this.createSession(data);
    return data;
  };

  logout = async () => {
    this.destroySession();
  };

  sendForgotPasswordEmail = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.FORGOT_PASSWORD,
      payload
    );
    return data;
  };

  resetPassword = async (payload) => {
    const { data } = await this.apiClient.patch(
      ENDPOINTS.RESET_PASSWORD,
      payload
    );
    return data;
  };

  updateUser = async (payload) => {
    const { data } = await this.apiClient.put(ENDPOINTS.UPDATE_USER, payload);
    return data;
  };

  changePassword = async (payload) => {
    const { data } = await this.apiClient.put(
      ENDPOINTS.CHANGE_PASSWORD,
      payload
    );
    return data;
  };

  validateToken = async (payload) => {
    const { data } = await this.apiClient.get(
      format(ENDPOINTS.VALIDATE_TOKEN, payload)
    );
    return data;
  };

  updateFileSettings = (data) => {
    return this.apiClient.put(ENDPOINTS.UPDATE_FILE_SETTINGS, data);
  };
}

const authService = new AuthService();
export default authService;
