import {
  PROMPT_TRANSITION,
  RESET_ALL_PROMPTS,
  SET_PROMPT_LIST,
} from "redux/constants/Prompt";

export const transition = (payload = null) => {
  return {
    type: PROMPT_TRANSITION,
    payload,
  };
};

export const setPromptList = (payload) => {
  return {
    type: SET_PROMPT_LIST,
    payload,
  };
};

export const resetAllPrompts = () => {
  return {
    type: RESET_ALL_PROMPTS,
  };
};
