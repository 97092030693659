import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NAV_TYPE_TOP } from "constants/ThemeConstant";
import utils from "utils";
import MenuContent from "./MenuContent";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import { NavProfile } from "./NavProfile";
import Flex from "components/shared-components/Flex";
import { ROUTES } from "routes";

import LanguageSwitcher from "./LanguageSwitcher";
export const TopNav = ({ topNavColor, localization = true }) => {
  const props = { topNavColor, localization };
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div
      className={`top-nav ${utils.getColorContrast(topNavColor)}`}
      style={{ backgroundColor: topNavColor, top: 0 }}
    >
      <Flex alignItems="center" justifyContent="end" className="vmg-nav">
        <div
          onClick={() => history.push(ROUTES.HOME)}
          className="mr-auto cursor-pointer"
        >
          <img
            src="/img/logo.png"
            alt={`logo`}
            style={{ height: "2.5rem", objectFit: "contain" }}
          />
        </div>
        <MenuContent type={NAV_TYPE_TOP} {...props} />
        <Flex alignItems="center">
          <NavProfile className="mr-2 ml-3" />
          <Button
            size="small"
            type="primary"
            shape="circle"
            data-cy="logoutButton"
            id="logout-button"
            icon={<LogoutOutlined />}
            onClick={() => dispatch(signOut())}
          />
        </Flex>
        <LanguageSwitcher />
      </Flex>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { topNavColor } = theme;
  return { topNavColor };
};

export default connect(mapStateToProps)(TopNav);
