import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "./configs/AppConfig";

export const ROUTES = {
  LOGIN: `${AUTH_PREFIX_PATH}/login`,
  FORGOT_PASSWORD: `${AUTH_PREFIX_PATH}/forgot-password`,
  HOME: `${APP_PREFIX_PATH}/home`,
  COMPARE: `${APP_PREFIX_PATH}/compare/`,
  CONVERT: `${APP_PREFIX_PATH}/convert/`,
  UPLOAD: `${APP_PREFIX_PATH}/upload`,
  PROJECT: `${APP_PREFIX_PATH}/projects/`,
  EDIT_TRANSCRIPT: `${APP_PREFIX_PATH}/transcript/`,
  PRICING_PAGE: `${APP_PREFIX_PATH}/prices/`,
  CHECKOUT: `${APP_PREFIX_PATH}/checkout/`,
  SETTINGS: `${APP_PREFIX_PATH}/settings`,
  REGISTER: `${AUTH_PREFIX_PATH}/register`,
};
