import ApiService from "./ApiService";

const ENDPOINTS = {
  CREATE_SUBSCRIPTION: "/subscription/",
  CREATE_CUSTOMER: "/subscription/customer",
  GET_USER_SUBSCRIPTION: "/subscription/my-subscription",
  UNSUBSCRIBE: "/subscription/cancel/",
  RESUBSCRIBE: "/subscription/reactivate/",
  PREVIEW_UPDATE: "/subscription/preview",
  UPDATE_SUBSCRIPTION: "/subscription/",
  START_TRIAL: "/subscription/trial",
  INVITE: "/invite/",
  UNINVITE: "/invite/",
  ACCEPT_INVITE: "/invite/accept",
  CONNECTED_SUBS: "/subscription/connected",
  REMOVE_CONNECTED: "/subscription/connected/",
  CHECK_COUPON: "/subscription/coupon",
};

class SubscriptionService extends ApiService {
  createSubscription = async ({ plan_id, price_id }) => {
    return await this.apiClient.post(ENDPOINTS.CREATE_SUBSCRIPTION, {
      plan_id,
      price_id,
    });
  };

  createCustomer = async ({
    plan_id,
    price_id,
    payment_method_id,
    coupon_id,
  }) => {
    return await this.apiClient.post(ENDPOINTS.CREATE_CUSTOMER, {
      plan_id,
      price_id,
      payment_method_id,
      coupon_id,
    });
  };

  getUserSubscription = async () => {
    return await this.apiClient.get(ENDPOINTS.GET_USER_SUBSCRIPTION);
  };

  unsubscribe = async (subscriptionId) => {
    return await this.apiClient.post(ENDPOINTS.UNSUBSCRIBE + subscriptionId);
  };

  resubscribe = async (subscriptionId) => {
    return await this.apiClient.post(ENDPOINTS.RESUBSCRIBE + subscriptionId);
  };

  updateSubscription = async (payload) => {
    return await this.apiClient.put(
      ENDPOINTS.UPDATE_SUBSCRIPTION + payload.subscription_id,
      payload
    );
  };

  previewUpdate = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.PREVIEW_UPDATE, payload);
  };

  startTrial = async () => {
    return await this.apiClient.post(ENDPOINTS.START_TRIAL);
  };

  inviteUser = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.INVITE, { email: payload });
  };

  acceptInvite = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.ACCEPT_INVITE, {
      token: payload,
    });
  };

  checkCoupon = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.CHECK_COUPON, { code: payload });
  };

  deleteInvite = async (inviteId) => {
    return await this.apiClient.delete(ENDPOINTS.UNINVITE + inviteId);
  };

  getConnectedSubscriptions = async () => {
    return await this.apiClient.get(ENDPOINTS.CONNECTED_SUBS);
  };

  removeConnectedSubscription = async ({ subscription_id }) => {
    return await this.apiClient.delete(
      ENDPOINTS.REMOVE_CONNECTED + subscription_id
    );
  };
}

export default new SubscriptionService();
