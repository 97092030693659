import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_PLANS: "/plans/?type=%s&continent=%s",
  GET_PLAN: "/plans/%s",
};

class PlanService extends ApiService {
  getPlans = async ({ planType, continent_code }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_PLANS, planType, continent_code.toLowerCase())
    );
  };
  getPlan = async (planId) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_PLAN, planId));
  };
}

export default new PlanService();
