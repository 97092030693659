const enTranslationMessages = require("./translations/en.json");
const esTranslationMessages = require("./translations/es.json");
const ptTranslationMessages = require("./translations/pt.json");

const lang = window.localStorage.getItem("lang");
const DEFAULT_LOCALE = lang ? lang : "en";

export const languages = {
  en: {
    lang: "en",
    label: "English",
    icon: "https://flagcdn.com/w320/us.png",
  },
  es: {
    lang: "es",
    label: "Spanish",
    icon: "https://flagcdn.com/w320/es.png",
  },
  pt: {
    lang: "pt",
    label: "Portuguese",
    icon: "https://flagcdn.com/w320/pt.png",
  },
};

const appLocales = Object.keys(languages);

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  es: formatTranslationMessages("es", esTranslationMessages),
  pt: formatTranslationMessages("pt", ptTranslationMessages),
};

export {
  appLocales,
  formatTranslationMessages,
  translationMessages,
  DEFAULT_LOCALE,
};
