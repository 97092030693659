import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { getUserInfo } from "redux/actions/Auth";

export const NavProfile = ({ signOut, className }) => {
  const dispatch = useDispatch();
  const user = useSelector(makeSelectCurrentUser());

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const full_name = useMemo(() => {
    const full_name = user?.full_name;
    return full_name;
  }, [user]);

  return (
    <div className={`rounded-pill pl-4 pr-4 pt-2 pb-2 bg-primary ${className}`}>
      <Typography.Text strong style={{ color: "#fff" }}>
        {full_name}
      </Typography.Text>
    </div>
  );
};

export default connect(null, { signOut })(NavProfile);
