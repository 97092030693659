import {
  CREATE_SUBSCRIPTION_PAYPAL,
  GET_SUBSCRIPTION_PAYPAL,
  PREVIEW_INVOICE_PAYPAL,
  PREVIEW_UPDATE_SUBSCRIPTION_PAYPAL,
  RESUBSCRIBE_PAYPAL,
  SET_PAYPAL_SUBSCRIPTION,
  UNSUBSCRIBE_PAYPAL,
  UPDATE_SUBSCRIPTION_PAYPAL,
} from "redux/constants/PaypalSubscription";

export const createSubscription = (payload) => ({
  type: CREATE_SUBSCRIPTION_PAYPAL,
  payload,
});

export const unsubscribePaypal = (payload) => ({
  type: UNSUBSCRIBE_PAYPAL,
  payload,
});

export const resubscribePaypal = (payload) => ({
  type: RESUBSCRIBE_PAYPAL,
  payload,
});

export const updateSubscriptionPaypal = (payload) => ({
  type: UPDATE_SUBSCRIPTION_PAYPAL,
  payload,
});

export const previewInvoicePaypal = (payload) => ({
  type: PREVIEW_INVOICE_PAYPAL,
  payload,
});

export const previewUpdatePaypal = (payload) => ({
  type: PREVIEW_UPDATE_SUBSCRIPTION_PAYPAL,
  payload,
});

export const getPaypalSubscription = (payload) => ({
  type: GET_SUBSCRIPTION_PAYPAL,
  payload,
});
export const setPaypalSubscription = (payload) => ({
  type: SET_PAYPAL_SUBSCRIPTION,
  payload,
});
