import React from "react";
import { APP_NAME } from "configs/AppConfig";
import { footerOptionsList } from "helpers/footerList";
import translations from "./translations";
import { useIntl } from "react-intl";
import { useIsMobile } from "helpers/useIsMobile";
import Icon, {
  YoutubeFilled,
  LinkedinFilled,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { Button } from "antd";

const icons = [
  {
    icon: YoutubeFilled,
    url: "https://www.youtube.com/channel/UCPLfbnTDcyyWTaDKUQ3SiRA",
  },
  {
    icon: LinkedinFilled,
    url: "https://www.linkedin.com/company/victor-voice/",
  },
  { icon: TwitterOutlined, url: "https://twitter.com/victor__voice" },
  {
    icon: InstagramOutlined,
    url: "https://www.instagram.com/victor__voice/",
  },
];

export default function Footer() {
  const { formatMessage } = useIntl();
  const { isMobile } = useIsMobile();

  const optionsList = [
    {
      name: formatMessage(translations.whatsNewText),
      link: "https://victormediagroup.notion.site/Victor-Voice-053a3237fa264c099b174672c206ae58",
    },
    {
      name: formatMessage(translations.resourcesText),
      link: "https://victormediagroup.notion.site/Resources-4039fe2270b24dc8b8a53abd870f60ce",
    },
    {
      name: formatMessage(translations.aboutText),
      link: "https://victormediagroup.notion.site/About-3981e0c4ed024183bfbd38a9278aece0",
    },
    {
      name: formatMessage(translations.FAQText),
      link: "https://victormediagroup.notion.site/FAQ-9ada55d77d55434f8d4bd8eb6e7390ef",
    },
    {
      name: formatMessage(translations.privacyTermsText),
      link: "https://victormediagroup.notion.site/Terms-of-Service-328fdbd74fec44f28ff89279aa345df4",
    },
    {
      name: formatMessage(translations.privacyText),
      link: "https://victormediagroup.notion.site/Privacy-Policy-590f808c38974497a3264bbc493cbc5a",
    },
  ];

  return (
    <footer className={!isMobile && "footer"}>
      {isMobile ? (
        <div
          style={{
            background: "#0034A6",
            textAlign: "center",
            padding: "32px 16px",
          }}
        >
          <Text className="text-white font-weight-bold">
            {" "}
            {formatMessage(translations.connect)}{" "}
          </Text>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              margin: "16px 0",
            }}
          >
            {icons.map(({ icon, url }) => (
              <Button type="link" href={url} target="_blank">
                <Icon
                  component={icon}
                  className="cursor-pointer"
                  style={{ fontSize: "32px", color: "white" }}
                />
              </Button>
            ))}
          </div>
          <a
            href="mailto:support@victormediagroup.co"
            className="text-white font-italic"
          >
            support@victormediagroup.co
          </a>
        </div>
      ) : (
        <>
          <div>{footerOptionsList(optionsList)}</div>
          <span>
            Copyright &copy; {`${new Date().getFullYear()}`}
            <span className="font-weight-semibold"> {`${APP_NAME}`} </span> All
            rights reserved.
          </span>
        </>
      )}
    </footer>
  );
}
