import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  GET_TRANSLATION_PER_PAGE,
  GET_TRANSLATION,
  RE_EDIT_TRANSLATION,
  DELETE_TRANSLATION,
  RENAME_TRANSLATION,
  GET_LANDING_TRANSLATION,
} from "../constants/Translations";
import {
  setTranslationPerPage,
  setTranslation,
  getTranslationPerPage as getTranslationPage,
  setLandingTranslation,
} from "../actions/Translations";
import { setSpeakerSamples, setVoices } from "../actions/TextToSpeech";
import TranslationService from "services/TranslationService";
import { NUMBER_PER_PAGE } from "constants/TranslationConstant";
import { DOWNLOAD_TRANSLATE_FILE } from "redux/constants/Translate";
import FileDownload from "js-file-download";

export function* getTranslationPerPage() {
  yield takeEvery(GET_TRANSLATION_PER_PAGE, function* ({ payload }) {
    try {
      const response = yield call(
        TranslationService.getTranslationPerPage,
        payload
      );
      yield put(setTranslationPerPage(response.data));
      yield put(setSpeakerSamples([]));
      yield put(setVoices([]));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* getTranslation() {
  yield takeEvery(GET_TRANSLATION, function* ({ payload, redirect }) {
    try {
      const response = yield call(TranslationService.getTranslation, payload);
      yield put(setTranslation(response.data));
    } catch (error) {
      console.log("error: ", error.response.data.message);
      message.error(error.response.data.message, 3).then(() => {
        redirect();
      });
    }
  });
}

export function* reEditTranslation() {
  yield takeEvery(
    RE_EDIT_TRANSLATION,
    function* ({ payload, setLoaderActive, redirectToProject }) {
      try {
        yield call(TranslationService.reEditTranslation, payload);

        yield setLoaderActive(false);
        yield redirectToProject.redirectToProjectPage(
          redirectToProject.project
        );
      } catch (error) {
        message.error(error.response?.data?.message, 3);
        console.log(error);
        setLoaderActive(false);
      }
    }
  );
}

export function* deleteTranslation() {
  yield takeEvery(DELETE_TRANSLATION, function* ({ payload }) {
    try {
      const response = yield call(
        TranslationService.deleteTranslation,
        payload.id
      );
      message.success(response.data.message, 3);
      yield put(
        getTranslationPage({
          orderBy: payload.orderBy,
          page: payload.page,
          perPage: NUMBER_PER_PAGE,
          filter: payload.filter,
          projectId: payload.projectId,
        })
      );
      payload.setLoaderActive(false);
    } catch (error) {
      message.error("Unknown Error Occurred");
      payload.setLoaderActive(false);
      console.log("error: ", error.response.data.message);
    }
  });
}

export function* createLandingTranslation() {
  yield takeEvery(GET_LANDING_TRANSLATION, function* ({ payload }) {
    try {
      const response = yield call(TranslationService.createLandingTranslation, {
        text: payload.text,
        lang: payload.sourceLanguage,
        translate_to: payload.targetLanguage,
      });
      yield put(setLandingTranslation(response.data));
      payload.setFetching(false);
    } catch (error) {
      console.log(error);
    }
  });
}

export function* renameTranslation() {
  yield takeEvery(RENAME_TRANSLATION, function* ({ payload }) {
    try {
      const response = yield call(TranslationService.renameTranslation, {
        translation_id: payload.id,
        name: payload.name,
      });
      yield put(
        getTranslationPage({
          orderBy: payload.orderBy,
          page: payload.page,
          perPage: NUMBER_PER_PAGE,
          filter: payload.filter,
          projectId: payload.projectId,
        })
      );
      message.success(response.data.message, 3);
      payload.setLoaderActive(false);
    } catch (error) {
      payload.setLoaderActive(false);
      if (error?.response?.data?.detail[0]?.msg) {
        message.error(error?.response?.data?.detail[0]?.msg, 3);
      }
      console.log(error);
      console.log("error: ", error?.response?.data.detail);
    }
  });
}

export function* downloadTranslateFile() {
  yield takeEvery(
    DOWNLOAD_TRANSLATE_FILE,
    function* ({ payload, setLoaderActive }) {
      try {
        const response = yield call(
          TranslationService.downloadTranslationFile,
          payload
        );
        FileDownload(response.data, payload.name + payload.format);
        setLoaderActive(false);
      } catch (error) {
        console.log("error", error.response);
        setLoaderActive(false);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getTranslationPerPage),
    fork(getTranslation),
    fork(reEditTranslation),
    fork(deleteTranslation),
    fork(renameTranslation),
    fork(downloadTranslateFile),
    fork(createLandingTranslation),
  ]);
}
