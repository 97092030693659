import {
  DELETE_PROJECT,
  GET_PROJECT,
  GET_PROJECTS_PER_PAGE,
  SET_PROJECT,
  SET_PROJECTS_PER_PAGE,
  UPDATE_PROJECT,
} from "../constants/Projects";

export const getProjectsPerPage = (payload) => {
  return {
    type: GET_PROJECTS_PER_PAGE,
    payload,
  };
};

export const setProjectsPerPage = (payload) => {
  return {
    type: SET_PROJECTS_PER_PAGE,
    payload,
  };
};

export const getProject = (payload, redirect) => ({
  type: GET_PROJECT,
  payload,
  redirect,
});
export const setProject = (payload) => ({ type: SET_PROJECT, payload });

export const updateProject = (payload) => ({ type: UPDATE_PROJECT, payload });

export const deleteProject = (payload) => ({ type: DELETE_PROJECT, payload });
