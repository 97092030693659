import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_TRANSLATIONS: "translation/?",
  GET_LANGUAGES: "translate/languages",
  GET_TRANSLATION: "translation/",
  RE_EDIT_TRANSLATION: "translation/%s/translate",
  DOWNLOAD_TRANSLATION_FILE: "/translation/export_file/%s/%s",
  TRANSLATE_LANDING: "translate/landing",
};

class TranslationService extends ApiService {
  getTranslationPerPage = async ({
    orderBy,
    filter,
    page,
    perPage,
    projectId = "",
  }) => {
    const url = new URLSearchParams();
    url.append("order_by", orderBy);
    url.append("filter", filter);
    url.append("page", page);
    url.append("size", perPage);
    url.append("project_id", projectId);
    return await this.apiClient.get(
      ENDPOINTS.GET_TRANSLATIONS + url.toString()
    );
  };

  getLanguages = () => {
    return this.apiClient.get(ENDPOINTS.GET_LANGUAGES);
  };

  getTranslation = async (payload) => {
    return await this.apiClient.get(`${ENDPOINTS.GET_TRANSLATION}${payload}`);
  };

  createLandingTranslation = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.TRANSLATE_LANDING, payload);
  };

  createTranslation = (payload) => {
    return this.apiClient.post(ENDPOINTS.GET_TRANSLATION, payload);
  };

  deleteTranslation = (payload) => {
    return this.apiClient.delete(ENDPOINTS.GET_TRANSLATION + payload);
  };

  reEditTranslation = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.RE_EDIT_TRANSLATION, payload.translation_id),
      payload
    );
  };
  renameTranslation = (payload) => {
    return this.apiClient.patch(
      ENDPOINTS.GET_TRANSLATION + payload.translation_id,
      payload
    );
  };
  downloadTranslationFile = (data) => {
    return this.apiClient.get(
      format(
        ENDPOINTS.DOWNLOAD_TRANSLATION_FILE,
        data.id,
        data.format.substring(1)
      ),
      { responseType: "blob" }
    );
  };
}
export default new TranslationService();
