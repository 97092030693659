import produce from "immer";
import {
  SET_LANGUAGES,
  SET_TRANSCRIPT,
  SET_TRANSCRIPT_PER_PAGE
} from "../constants/Transcript";

const initState = {
  transcriptList: [],
  languages: [],
  transcript: null
};

const transcriptReducer = (state = initState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_TRANSCRIPT_PER_PAGE:
        draft.transcriptList = {
          count: payload?.total,
          current: payload?.page,
          size: payload?.size,
          results: payload?.items,
        };
        break;
      case SET_LANGUAGES:
        draft.languages = payload;
        break;
      case SET_TRANSCRIPT:
        draft.transcript = payload;
        break;

      default:
        return state;
    }
  });
export default transcriptReducer;
