import mixpanel from "mixpanel-browser";
import { env } from "../configs/EnvironmentConfig.js";
mixpanel.init(env.MIXPANEL_TOKEN);

let isActive = !!env.MIXPANEL_ACTIVE;

let actions = {
  identify: (id) => {
    if (isActive) mixpanel.identify(id);
  },
  alias: (id) => {
    if (isActive) mixpanel.alias(id);
  },
  track: (eventName, props) => {
    if (isActive) mixpanel.track(eventName, props);
  },
  people: {
    set: (props) => {
      if (isActive) mixpanel.people.set(props);
    },
    set_once: (props) => {
      if (isActive) mixpanel.people.set_once(props);
    },
  },
  time_event: (eventName) => {
    if (isActive) mixpanel.time_event(eventName);
  },
};

const Analytics = actions;

export default Analytics;
