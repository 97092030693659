import {
  GET_SPEAKERS_SAMPLES,
  SET_SPEAKERS_SAMPLES,
  GET_VOICES,
  SET_VOICES,
  CONVERT_TEXT_TO_SPEECH,
  GET_VOICE_EXAMPLE,
} from "../constants/TextToSpeech";

export const getSpeakerSamples = (payload) => {
  return {
    type: GET_SPEAKERS_SAMPLES,
    payload,
  };
};

export const setSpeakerSamples = (payload) => {
  return {
    type: SET_SPEAKERS_SAMPLES,
    payload,
  };
};

export const getVoices = () => {
  return {
    type: GET_VOICES,
  };
};

export const setVoices = (payload) => {
  return {
    type: SET_VOICES,
    payload,
  };
};

export const convertTextToSpeech = (payload, loader, projectId, history) => {
  return {
    type: CONVERT_TEXT_TO_SPEECH,
    payload,
    loader,
    projectId,
    history,
  };
};

export const getVoiceExample = (payload, playAudio) => {
  return {
    type: GET_VOICE_EXAMPLE,
    payload,
    playAudio,
  };
};
