import {
  UPLOAD_SPEECH_FILE,
  SET_ALL_LANGUAGES,
  GET_ALL_LANGUAGES,
} from "../constants/UploadFile";

export const uploadSpeechFile = (payload, redirect) => {
  return {
    type: UPLOAD_SPEECH_FILE,
    payload,
    redirect,
  };
};

export function getAllLanguages() {
  return {
    type: GET_ALL_LANGUAGES,
  };
}

export const setAllLanguages = (payload) => {
  return {
    type: SET_ALL_LANGUAGES,
    payload,
  };
};
