import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Transcript from "./Transcript";
import UploadFile from "./UploadFile";
import Translate from "./Translate";
import TextToSpeech from "./TextToSpeech";
import Projects from "./Projects";
import Translations from "./Translations";
import Audio from "./Audio";
import Plan from "./Plan";
import Subscription from "./Subscription";
import Invoice from "./Invoice";
import Card from "./Card";
import PaypalSubscription from "./PaypalSubscription";
import Location from "./Location";
import Newsletter from "./Newsletter";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Transcript(),
    UploadFile(),
    Translate(),
    TextToSpeech(),
    Projects(),
    Translations(),
    Audio(),
    Plan(),
    Subscription(),
    Invoice(),
    Card(),
    PaypalSubscription(),
    Location(),
    Newsletter(),
  ]);
}
