import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setCard } from "redux/actions/Card";
import { CHANGE_CARD, GET_CARD } from "redux/constants/Card";
import CardService from "services/CardService";
import { Modal } from "antd";

export function* getUserCard() {
  yield takeEvery(GET_CARD, function* () {
    try {
      const response = yield call(CardService.getUserCard);
      yield put(setCard(response.data));
    } catch (error) {
      console.log("error", error.response);
    }
  });
}

export function* changeCard() {
  yield takeEvery(CHANGE_CARD, function* ({ payload }) {
    try {
      // yield put(setCard(null));
      const response = yield call(CardService.changeCard, payload);
      if (response?.data) {
        yield put(setCard(response.data));
      }
      payload.setShowForm(false);
    } catch (error) {
      Modal.error({ title: "Error", content: error?.response?.data?.detail });
      console.log("error", error.response);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUserCard), fork(changeCard)]);
}
