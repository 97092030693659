import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import Analytics from "analytics";
import utils from "utils";
import { ROUTES } from "routes";
import { formatPathName } from "helpers/formatPathName";
import {
  resetAllPrompts,
  setPromptList,
  transition,
} from "redux/actions/Prompt";
import {
  NEW_TRANSLATION_BUTTON,
  DOWNLOAD_AUDIO_PROMPT,
} from "components/shared-components/HelpPrompt/constants/ProjectPagePrompts";

export const Views = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = props;
  useEffect(() => {
    // sends a timed event every time we leave a page, with the path
    const unlisten = history.listen((newLocation, action) => {
      let oldPathName = formatPathName(location.pathname);
      let newPathName = formatPathName(newLocation.pathname);

      dispatch(resetAllPrompts());

      if (newPathName === ROUTES.PROJECT) {
        if (oldPathName === ROUTES.EDIT_TRANSCRIPT) {
          dispatch(setPromptList([NEW_TRANSLATION_BUTTON]));
          dispatch(transition(0));
        }

        if (oldPathName === ROUTES.CONVERT) {
          dispatch(setPromptList([DOWNLOAD_AUDIO_PROMPT]));
          dispatch(transition(0));
        }
      }
    });
    Analytics.time_event("Page View");
    return () => {
      unlisten();
      let pageInfo = utils.getRouteMatch(location.pathname);
      if (pageInfo) {
        Analytics.track("Page View", {
          "Page Title": pageInfo.title,
          Path: pageInfo.path,
        });
      }
    };
  }, [location]);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={AUTH_PREFIX_PATH + "/splash"} />
      </Route>
      <Route path={AUTH_PREFIX_PATH + "/splash"}>
        <AppLayout location={location} isSplash={true} />
      </Route>
      <Route path={AUTH_PREFIX_PATH}>
        <AuthLayout />
      </Route>
      <Route path={APP_PREFIX_PATH}>
        <AppLayout location={location} />
      </Route>
    </Switch>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
