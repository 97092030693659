import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { useRef } from "react";
import "./index.css";
import { isActivePrompt, selectActivePrompt } from "redux/selectors/Prompt";
import POSITIONS from "./constants/positions";
import { transition } from "redux/actions/Prompt";
import {
  CHOICE_PROMPT,
  TRANSCRIPT_EDITOR_BUTTON,
  UPLOAD_BUTTON,
} from "./constants/UploadPagePrompts";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { SETTINGS_BUTTON } from "./constants/HomepagePrompts";

const HelpPrompt = ({
  children,
  promptName,
  renderContent,
  arrowPosition = POSITIONS.TOP,
  visible = true,
}) => {
  const dispatch = useDispatch();
  const isActive = useSelector(isActivePrompt(promptName));
  const componentRef = useRef(null);
  const tooltip = document.getElementById("tooltip-root");
  const currentUser = useSelector(makeSelectCurrentUser());
  const activePrompt = useSelector(selectActivePrompt());

  const onClickPrompt = (event) => {
    if ([CHOICE_PROMPT, "empty"].includes(promptName)) {
      return;
    }
    event.stopPropagation();
    event.stopImmediatePropagation();

    dispatch(transition());
  };

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      dispatch(transition());
    }
  };

  useEffect(() => {
    if (!isActive) {
      return document.removeEventListener("mouseup", handleClickOutside);
    }

    if (SETTINGS_BUTTON === promptName) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [componentRef, isActive]);

  useEffect(() => {
    if (isActive && currentUser?.help_prompts) {
      const anchorCoords = componentRef.current.getBoundingClientRect();
      // TODO: Calculations depending on size of tooltip and arrow positioning
      tooltip.style.display = "flex";
      tooltip.className = `help-prompt ${arrowPosition}`;
      const scroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      switch (arrowPosition) {
        case POSITIONS.TOP:
          tooltip.style.top = anchorCoords.bottom + 15 + scroll + "px";
          tooltip.style.left = `${
            anchorCoords.left - tooltip.offsetWidth / 2 + anchorCoords.width / 2
          }px`;
          break;
        case POSITIONS.BOTTOM:
          tooltip.style.top =
            anchorCoords.top - tooltip.offsetHeight - 15 + scroll + "px";
          tooltip.style.left = `${
            anchorCoords.left - tooltip.offsetWidth / 2 + anchorCoords.width / 2
          }px`;
          break;
        case POSITIONS.LEFT:
          tooltip.style.top = `${anchorCoords.top + scroll}px`;
          tooltip.style.left = anchorCoords.right + 15 + "px";
          break;
        case POSITIONS.RIGHT:
          tooltip.style.top = `${anchorCoords.top + scroll}px`;
          tooltip.style.left =
            anchorCoords.left - tooltip.offsetWidth - 15 + "px";
          break;
        case POSITIONS.NONE:
          tooltip.style.top =
            componentRef.current.offsetHeight + anchorCoords.top + "px";
          tooltip.style.left =
            anchorCoords.left +
            anchorCoords.width / 2 -
            tooltip.offsetWidth / 2 +
            "px";
          break;
        default:
          break;
      }
      if (promptName === "empty") {
        tooltip.style.display = "none";
      }
      if (!visible) {
        tooltip.style.display = "none";
      }
      return () => {
        tooltip.className = "";
      };
    }
  }, [isActive, visible]);

  useEffect(() => {
    if (isActive) {
      tooltip.addEventListener("mouseup", onClickPrompt);
    }
    return () => {
      tooltip.removeEventListener("mouseup", onClickPrompt);
    };
  });

  return (
    <>
      {React.cloneElement(children, {
        setContentRef: (node) => (componentRef.current = node),
        ref: componentRef,
        onClick: () => {
          if (typeof children?.props?.onClick === "function") {
            children?.props?.onClick();
          }
          if (
            activePrompt === promptName &&
            ![
              SETTINGS_BUTTON,
              UPLOAD_BUTTON,
              TRANSCRIPT_EDITOR_BUTTON,
            ].includes(promptName)
          ) {
            dispatch(transition());
          }
        },
      })}

      {isActive && currentUser?.help_prompts
        ? createPortal(renderContent(), tooltip)
        : null}
    </>
  );
};

export default HelpPrompt;
