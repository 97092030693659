import { message } from "antd";
import { all, call, fork, takeEvery } from "redux-saga/effects";
import { ADD_NEWSLETTER } from "redux/constants/Newsletter";
import NewsletterService from "services/NewsletterService";

export function* addNewsletter() {
  yield takeEvery(ADD_NEWSLETTER, function* ({ payload, onSuccess, callback }) {
    try {
      yield call(NewsletterService.addNewsletter, payload);
      onSuccess();
    } catch (error) {
      if (error?.response?.data?.detail) {
        message.error(error.response.data.detail, 3);
      }
    } finally {
      callback();
    }
  });
}

export default function* rootSaga() {
  yield all([fork(addNewsletter)]);
}
