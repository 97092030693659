import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export const useIsMobile = () => {
  const theme = useTheme();
  const matchesMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(matchesMedia);
  }, [setMobile, matchesMedia]);

  return { isMobile, setMobile };
};
